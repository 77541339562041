/** @format */

import { getApi } from "../axios";

export default {
    async getPayments(queryString) {
        return await (
            await getApi({
                baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
                auth: true,
            })
        ).get(`admin/payments${queryString}`);
    },
    async patchPayment({ shopId, id, status }) {
        return await (
            await getApi({
                baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
                auth: true,
            })
        ).patch(`admin/shops/${shopId}/payments/${id}/status/${status}`);
    },
};
