/** @format */

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useTheme
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { DatePickerField } from 'components/common/DatePickerField/DatePickerField';

import validationSchema from 'validation/GroupDetails/Header/Schema';
import { useHeaderLogic } from '../hooks/useHeaderLogic';
import { useStyles } from './styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { getApi } from 'api/axios';
import { errorToast, successToast } from 'components/common/Toast/Toast';
import { useGroupAPI } from 'pages/PhotoAdmin/SelectShop/hooks/useGroupAPI';
import { useNavigate } from 'react-router-dom';

const HeaderForm = ({
  createModal,
  header,
  parentHeader,
  setHeader,
  setHeaderSubmitted,
  buttonRef,
  disabled,
  currentModifiers,
  setCurrentModifiers,
  headerDateChanged,
  setHeaderDateChanged,
  resetHeader
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isReopen, setIsReopen] = useState(false);
  const [isGenerate, setIsGenerate] = useState(false);
  const { fetchInnerGroups } = useGroupAPI();
  const navigate = useNavigate();

  const { getGroupType } = useHeaderLogic();
  const today = new Date();

  const getUriId = (segment) => {
    return +segment.match('mod#id:[0-9]*')[0]?.split(':')[1];
  };

  useEffect(() => {
    if (createModal && header?.belongsTo !== null && !headerDateChanged) {
      let copy = currentModifiers?.map((el) => {
        let elCopy = { ...el };
        console.log(
          header?.belongsTo?.productGroupModifierValidities?.find(
            (find) => getUriId(find.modifierUriSegment) === getUriId(el.modifierUriSegment)
          )?.validUntil
        );
        let value = new Date(
          header?.belongsTo?.productGroupModifierValidities?.find(
            (find) => getUriId(find.modifierUriSegment) === getUriId(el.modifierUriSegment)
          )?.validUntil
            ? header?.belongsTo?.productGroupModifierValidities?.find(
                (find) => getUriId(find.modifierUriSegment) === getUriId(el.modifierUriSegment)
              )?.validUntil
            : new Date()
        );
        let newDateValue = new Date(value.setUTCHours(23, 59, 59));
        newDateValue.setUTCDate(newDateValue.getUTCDate());
        elCopy.validUntil = new Date(newDateValue)?.toISOString()?.slice(0, 19);
        return elCopy;
      });
      setCurrentModifiers(copy);
    }
  }, [createModal, header]);

  const reopenGallery = async () => {
    try {
      const res = await (
        await getApi({
          baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL
        })
      ).post(`/admin/shops/${header?.shopId}/groups/${header?.id}/re-open`);
      if (res.status >= 200 && res.status < 300) {
        successToast(`Sikeres újraaktiválás!`);
        resetHeader();
        fetchInnerGroups({ id: header?.id });
        navigate(`/shops/${header?.shopId}/administration/galleries/${header?.id}`);
        setIsReopen(false);
      }
    } catch (e) {
      errorToast(`Sikertelen újraaktiválás!`);
      console.log(e);
    }
  };

  const regenerateKey = async () => {
    try {
      const res = await (
        await getApi({
          baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL
        })
      ).post(`/admin/shops/${header?.shopId}/groups/${header?.id}/generate-key`);
      if (res.status >= 200 && res.status < 300) {
        successToast(`Sikeres újragenerálás!`);
        resetHeader();
        setIsGenerate(false);
      }
    } catch (e) {
      errorToast(`Sikertelen újragenerálás!`);
      console.log(e);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: header.name || '',
          category: header.category || '',
          belongsTo: header.belongsTo || null,
          accessKey: header.accessKey || '',
          searchKey: header.searchKey || '',
          isActive: createModal ? false : header.isActive || false,
          accessKeyInherited: createModal
            ? parentHeader
              ? true
              : false
            : header.belongsTo
            ? header.accessKeyInherited
            : false,
          inheritValidities: createModal
            ? parentHeader
              ? true
              : false
            : header.belongsTo
            ? header.inheritValidities
            : false,
          isSecured: false,
          inheritModifiers: createModal
            ? header.belongsTo
              ? true
              : false
            : header.belongsTo
            ? header.inheritModifiers
            : false
        }}
        style={{ width: '100%' }}
        validate={false}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setHeader({
            ...header,
            ...values,
            isSecured: !!values.accessKey
          });
          setSubmitting(false);
          setHeaderSubmitted(true);
          resetForm();
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
          <Form className={classes.form}>
            <Grid container alignContent='center' className={classes.grid}>
              {(header?.isPrinted || header?.isPrintingDone) && !header?.belongsTo ? (
                <div>
                  <Button
                    style={{ marginBottom: '1rem' }}
                    variant='outlined'
                    onClick={() => {
                      setIsReopen(true);
                    }}>
                    Iskola újranyitása
                  </Button>
                </div>
              ) : (
                ''
              )}

              <Grid item xs={12}>
                {!(header.name === 'Csoportképek' || header.name === 'Group photos') && (
                  <FormControlLabel
                    style={{ width: '90%' }}
                    labelPlacement='top'
                    label={`${getGroupType(header)} neve`}
                    className={classes.formlabel}
                    control={
                      <TextField
                        variant='outlined'
                        required
                        id='name'
                        name='name'
                        autoFocus
                        disabled={
                          disabled || isSubmitting || values.name === 'Csoportképek' || values.name === 'Group photos'
                        }
                        value={values.name}
                        InputProps={{
                          style: {
                            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value !== 'Csoportképek' && e.target.value !== 'Group photos') {
                            handleChange(e);
                          } else {
                            alert('Lefoglalt név.');
                          }
                        }}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        style={{ width: '100%' }}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <div hidden={!!createModal || (getGroupType(header) !== 'Iskola' && values.accessKeyInherited)}>
                  <FormControlLabel
                    className={classes.formlabel}
                    control={
                      <TextField
                        variant='outlined'
                        type='searchKey'
                        id='searchKey'
                        name='searchKey'
                        value={values.searchKey}
                        className={classes.formControl}
                        onChange={handleChange}
                        error={touched.searchKey && Boolean(errors.searchKey)}
                        helperText={touched.searchKey && errors.searchKey}
                        style={{ width: '100%' }}
                        disabled={true}
                        InputProps={{
                          style: {
                            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
                          },
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                type='button'
                                aria-label='toggle copy to clipboard'
                                onClick={(e) => {
                                  navigator?.clipboard?.writeText(header.searchKey);
                                  e.stopPropagation();
                                }}>
                                <ContentCopyIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    }
                    style={{ width: '90%' }}
                    labelPlacement='top'
                    label={`${getGroupType(header)} kereső kulcsa`}
                  />
                  <Button
                    style={{ marginTop: '1rem' }}
                    variant='outlined'
                    onClick={() => {
                      setIsGenerate(true);
                    }}>
                    Keresőkulcs újragenerálása
                  </Button>
                </div>
                {(header?.inheritValidities === false || !header?.belongsTo) && (
                  <div
                    style={{
                      marginBottom: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '1rem'
                    }}>
                    <h2>Rendelési határidők</h2>
                    {currentModifiers?.map((el) => {
                      return el.priority === 1 ? (
                        <div
                          style={{
                            marginTop: '1rem',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            columnGap: '1.5rem'
                          }}
                          key={el.id}>
                          <div
                            style={{
                              width: '100px',
                              fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
                            }}>
                            {el.name}:
                          </div>
                          <DatePickerField
                            name='validUntil'
                            minDate={today}
                            // maxDate={id === 1 ? maxPaperDate : maxDigitalDate}
                            openTo='month'
                            setDate={(value) => {
                              setHeaderDateChanged(true);
                              let copy = currentModifiers.map((m) => {
                                let mCopy = {
                                  ...m
                                };
                                if (mCopy.id === el.id) {
                                  let newDateValue = new Date(value.setUTCHours(23, 59, 59));
                                  if (createModal) {
                                    newDateValue.setUTCDate(newDateValue.getUTCDate() + 1);
                                  } else {
                                    newDateValue.setUTCDate(newDateValue.getUTCDate());
                                  }
                                  mCopy.validUntil = new Date(newDateValue).toISOString().slice(0, 19);
                                } else {
                                  if (el.compatibleWith.includes(getUriId(mCopy.modifierUriSegment))) {
                                    let newDateValue = new Date(value.setUTCHours(23, 59, 59));
                                    if (createModal) {
                                      newDateValue.setUTCDate(newDateValue.getUTCDate() + 1);
                                    } else {
                                      newDateValue.setUTCDate(newDateValue.getUTCDate());
                                    }
                                    mCopy.validUntil = new Date(newDateValue).toISOString().slice(0, 19);
                                  }
                                }
                                return mCopy;
                              });
                              setCurrentModifiers(copy);
                            }}
                            views={['year', 'month', 'day']}
                            value={
                              createModal
                                ? new Date(
                                    header?.belongsTo?.productGroupModifierValidities?.find(
                                      (find) => getUriId(find.modifierUriSegment) === getUriId(el.modifierUriSegment)
                                    )?.validUntil
                                  )
                                : new Date(el.validUntil)
                            }
                          />
                        </div>
                      ) : (
                        ''
                      );
                    })}
                  </div>
                )}
              </Grid>
              {!!header && !createModal && (
                <Grid item xs={header.belongsTo ? 6 : 12}>
                  <FormControlLabel
                    className={classes.formlabel}
                    control={
                      <Checkbox
                        color='primary'
                        id='isActive'
                        defaultChecked={values.isActive}
                        value={values.isActive}
                        onChange={handleChange}
                        disabled={disabled || isSubmitting}
                      />
                    }
                    labelPlacement='top'
                    label='Aktív?'
                  />
                </Grid>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                {header?.belongsTo && (
                  <Grid item xs={6}>
                    <FormControlLabel
                      className={classes.formlabel}
                      control={
                        <Checkbox
                          color='primary'
                          id='inheritModifiers'
                          checked={values.inheritModifiers}
                          value={values.inheritModifiers}
                          onChange={(e) => {
                            handleChange(e);
                            setHeader({
                              ...header,
                              inheritModifiers: e.target.checked,
                              isSecured: !!values.accessKey
                            });
                          }}
                          disabled={disabled || isSubmitting}
                        />
                      }
                      style={{ paddingTop: '5px' }}
                      enableReinitialize
                      labelPlacement='top'
                      label='Árak öröklése'
                    />
                  </Grid>
                )}
                {header.belongsTo && !(header.name === 'Csoportképek' || header.name === 'Group photos') && (
                  <Grid item xs={6}>
                    <FormControlLabel
                      className={classes.formlabel}
                      control={
                        <Checkbox
                          color='primary'
                          id='accessKeyInherited'
                          checked={values.accessKeyInherited}
                          value={values.accessKeyInherited}
                          onChange={(e) => {
                            handleChange(e);
                            setHeader({
                              ...header,
                              accessKeyInherited: e.target.checked
                            });
                          }}
                          disabled={disabled || isSubmitting}
                        />
                      }
                      style={{ paddingTop: '5px' }}
                      enableReinitialize
                      labelPlacement='top'
                      label='Keresőkulcs öröklése'
                    />
                  </Grid>
                )}
                {header.belongsTo && !(header.name === 'Csoportképek' || header.name === 'Group photos') && (
                  <Grid item xs={6}>
                    <FormControlLabel
                      className={classes.formlabel}
                      control={
                        <Checkbox
                          color='primary'
                          id='inheritValidities'
                          checked={values.inheritValidities}
                          value={values.inheritValidities}
                          onChange={(e) => {
                            handleChange(e);
                            setHeader({
                              ...header,
                              inheritValidities: e.target.checked
                            });
                          }}
                          disabled={disabled || isSubmitting}
                        />
                      }
                      style={{ paddingTop: '5px' }}
                      enableReinitialize
                      labelPlacement='top'
                      label='Határidők öröklése'
                    />
                  </Grid>
                )}
              </div>
            </Grid>
            <button type={'submit'} ref={buttonRef} onClick={handleSubmit} hidden>
              Submit
            </button>
          </Form>
        )}
      </Formik>
      <ConfirmDialog
        open={isReopen}
        title={
          'A galéria újranyitásával lehetőséget biztosítasz a nyomtatott képek újbóli megvásárlására. Ezen művelettel várhatóan újabb nyomtatásra és kiszállításra lesz szükség. Biztosan újranyitod a galériát?'
        }
        functionButtonText={'Újranyitás'}
        functionButtonColor={'green'}
        closeButtonText={'Mégse'}
        onOk={() => {
          reopenGallery();
        }}
        onClose={() => {
          setIsReopen(false);
        }}
        multipleButtons></ConfirmDialog>
      <ConfirmDialog
        open={isGenerate}
        title={
          'A keresőkulcs újragenerálásával az iskola a régi kulccsal már nem lesz elérhető. A vásárláshoz az ügyfelek csak az új kulccsal fogják elérni a galériát. Biztosan újragenerálod a kereső kulcsot?'
        }
        functionButtonText={'Újragenerálás'}
        functionButtonColor={'green'}
        closeButtonText={'Mégse'}
        onOk={() => {
          regenerateKey();
        }}
        onClose={() => {
          setIsGenerate(false);
        }}
        multipleButtons></ConfirmDialog>
    </div>
  );
};

export default HeaderForm;
