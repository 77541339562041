import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  batches: [],
  fileCount: 0,
  completedUploads: 0,
  errorCount: 0,
  loading: false
};

export const uploadSlice = createSlice({
  name: 'uploadSlice',
  initialState: initialState,
  reducers: {
    clearUploads: (state) => {
      state.batches = [];
      state.fileCount = 0;
      state.completedUploads = 0;
    },
    addBatchResults: (state, action) => {
      state.batches.push(action.payload);
    },
    updateBatchResults: (state, action) => {
      state.batches.forEach((batch) => {
        if (batch[0].promiseConfig.name === action.payload[0].promiseConfig.name) {
          batch = action.payload;
        }
      });
    },
    updateResult: (state, action) => {
      state.batches.forEach((batch) => {
        batch.forEach((upload) => {
          if (upload.promiseConfig.name === action.payload.promiseConfig.name) {
            upload.success = action.payload.success;
          }
        });
      });
    },
    setFileCount: (state, action) => {
      if (!isNaN(parseInt(action.payload))) {
        state.fileCount = action.payload;
      }
    },
    incrementCompletedUploads: (state, action) => {
      state.completedUploads += 1;
    },
    resetErrorCount: (state, action) => {
      state.errorCount = 0;
    },
    incrementErrorCount: (state, action) => {
      state.errorCount += 1;
    },
    decrementErrorCount: (state, action) => {
      state.errorCount -= 1;
    },
    setUploadsLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const {
  addBatchResults,
  clearUploads,
  updateResult,
  updateBatchResults,
  setFileCount,
  incrementCompletedUploads,
  resetErrorCount,
  incrementErrorCount,
  decrementErrorCount,
  setUploadsLoading
} = uploadSlice.actions;

export default uploadSlice.reducer;
