import { getApi } from "api/axios";
import { useState } from "react";
import { useSelector } from "react-redux";

export const useGroupNameEditorLogic = () => {
  const [groupLoading, setGroupLoading] = useState();
  const [groups, setGroups] = useState();

  const shopId = useSelector(
    (state) => state.nonPersistedReducers.shopSliceReducer.shopId
  );

  const user = useSelector(
    (state) => state.nonPersistedReducers?.userSliceReducer?.currentUser
  );

  const fetchGroups = async () => {
    setGroupLoading(true);
    try {
      if (user?.groups?.find((group) => group === "printer_admin")) {
        setGroups(null);
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).get(`/admin/groups/filter?topLevel=true&page=1&size=10000`);
        if (res.status === 200) {
          const groups = res.data._embedded.groupDTOList.map((group) => ({
            ...group,
            selected: false,
          }));
          setGroupLoading(false);

          setGroups(groups);
        }
      } else {
        setGroups(null);
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).get(`/admin/shops/${shopId}/groups/filter?topLevel=true&page=1&size=10000`);
        if (res.status === 200) {
          const groups = res.data._embedded.groupDTOList.map((group) => ({
            ...group,
            selected: false,
          }));
          setGroupLoading(false);

          setGroups(groups);
        }
      }
    } catch (e) {
      setGroupLoading(false);
      console.log(e);
    }
  };

  return { fetchGroups, groups, groupLoading };
};
