import { Grid, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Autocomplete } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';

import { usePostalCodeFields } from './hooks/usePostalCodeFields';
import { useStyles } from './styles';

const PostalCodeFields = ({ data, setData, disabled, zipHelper, zipError, cityHelper, cityError }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { calcAddress, calcPostalCode, calcAddressSuggestions, calcZipSuggestions, zips, settlements } =
    usePostalCodeFields();

  useEffect(() => {
    calcZipSuggestions(data?.city, data?.zipCode);
    if (data?.zipCode?.length > 0) {
      const suggestion = calcAddress(data.zipCode);
      if (data?.city && settlements[Object.keys(settlements)?.find((key) => key === data?.city)] == data?.zipCode) {
        return;
      } else {
        setData({ ...data, city: suggestion || '' });
      }
    } else if (data?.city?.length > 1 && Object.keys(settlements)?.find((key) => key === data?.city)) {
      setData({ ...data, city: '' });
    }
  }, [data?.zipCode]);

  useEffect(() => {
    calcZipSuggestions(data?.city, data?.zipCode);
    if (data?.city?.length > 0) {
      const suggestion = calcPostalCode(data.city, data.zipCode);
      setData({ ...data, zipCode: suggestion || '' });
    } else if (data?.zipCode?.length > 1) {
      setData({ ...data, zipCode: '' });
    }
  }, [data?.city]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.value === value.value}
          disabled={disabled}
          disablePortal
          options={zips}
          filterOptions={(options) => options}
          inputValue={data?.zipCode}
          className={classes.autoComplete}
          sx={{ fontFamily: `${theme.typography.main}, Sans-serif, Arial` }}
          onChange={(e) =>
            setData({
              ...data,
              zipCode: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML
            })
          }
          renderInput={(params) => (
            <TextField
              id='zipCode'
              variant='outlined'
              required
              name='zipCode'
              value={data?.zipCode}
              className={classes.zip}
              {...params}
              label={'Irányítószám'}
              key='postal_code_field'
              onChange={(e) => {
                setData({ ...data, zipCode: e.target.value });
              }}
              error={zipHelper && Boolean(zipError)}
              helperText={zipHelper && zipError}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.value === value.value}
          disabled={disabled}
          disablePortal
          options={calcAddressSuggestions(data?.city)}
          filterOptions={(options) => options}
          inputValue={data?.city}
          className={classes.autoComplete}
          sx={{ fontFamily: `${theme.typography.main}, Sans-serif, Arial` }}
          onChange={(e) =>
            setData({
              ...data,
              city: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML
            })
          }
          renderInput={(params) => (
            <TextField
              id='city'
              variant='outlined'
              required
              name='city'
              value={data?.city}
              className={classes.city}
              {...params}
              label={'Város'}
              key='address_field'
              onChange={(e) => {
                setData({ ...data, city: e.target.value });
              }}
              error={cityHelper && Boolean(cityError)}
              helperText={cityHelper && cityError}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default PostalCodeFields;
