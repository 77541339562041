import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  autoComplete: {
    width: '100%'
  },
  input: {
    height: '30px',
    width: 'calc(100% - 1rem)',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'lightgrey',
    borderRadius: '20px',
    border: 'none',
    marginBottom: '0.5rem',
    outlineColor: 'black'
  },
  label: {
    fontWeight: 400,
    fontSize: '1rem'
  },
  zip: {},
  city: {},
  container: {
    padding: '0.1rem'
  }
}));
