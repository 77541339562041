import React, { useState } from 'react';
import ReactImageUploading from 'react-images-uploading';
import { useImageUploaderLogic } from './useImageUploaderLogic';
import styles from './ImageUploader.module.css';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ConfirmDialog from '../../common/ConfirmDialog/ConfirmDialog';
import { Button, CircularProgress } from '@material-ui/core';

function CircularProgressWithLabel({ value }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography variant='caption' component='div' color='text.secondary'>{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const ImageUploader = ({ id, setImage, image, url, fullWidth = false, variant = 'contained', handleDelete }) => {
  const { onChange, handleUpload, /* loading,*/ progress, imageList } = useImageUploaderLogic(id, setImage, url);

  const [deleteDialog, setDeleteDialog] = useState(false);

  return (
    <>
      <ReactImageUploading
        value={imageList}
        onChange={onChange}
        maxNumber={1}
        dataURLKey='data_url'
        acceptType={['jpg', 'jpeg']}>
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          // write your building UI
          <div className={styles.uploader}>
            {!image && (
              <Button
                variant={variant === 'outlined' ? 'outlined' : 'contained'}
                fullWidth={fullWidth}
                style={isDragging ? { color: 'red' } : { color: 'black' }}
                onClick={onImageUpload}
                {...dragProps}>
                Kattints vagy húzz ide egy képet
              </Button>
            )}

            {imageList.length < 1 && image && (
              <Button
                style={{ color: 'black' }}
                variant={variant == 'outlined' ? 'outlined' : 'contained'}
                fullWidth={fullWidth}
                onClick={() => {
                  setDeleteDialog(true);
                }}>
                Kép törlése
              </Button>
            )}
            {imageList.map((image, index) => (
              <div key={index} className={styles.imageItem}>
                {/* <img src={image['data_url']} alt='' width='100' /> */}
                <div className={styles.cancel_upload}>
                  <Button
                    style={{ color: 'black' }}
                    variant={variant === 'outlined' ? 'outlined' : 'contained'}
                    onClick={() => onImageUpdate(index)}>
                    Választok másikat
                  </Button>
                  <Button
                    style={{ color: 'black' }}
                    variant={variant === 'outlined' ? 'outlined' : 'contained'}
                    onClick={() => onImageRemove(index)}>
                    Mégsem
                  </Button>
                </div>
              </div>
            ))}
            {imageList.length > 0 && (
              <Button
                variant={variant === 'outlined' ? 'outlined' : 'contained'}
                fullWidth={fullWidth}
                style={{ color: 'black' }}
                onClick={() => handleUpload()}>
                feltölt
              </Button>
            )}
            {progress && progress < 100 ? <CircularProgressWithLabel value={progress} /> : <></>}
          </div>
        )}
      </ReactImageUploading>
      <ConfirmDialog
        open={deleteDialog}
        functionButtonText={'Törlés'}
        closeButtonText={'Mégse'}
        multipleButtons
        setOpen={setDeleteDialog}
        title={'Biztosan törölni szeretnéd a képet?'}
        onOk={() => {
          handleDelete();
          setImage(null);
          setDeleteDialog(false);
        }}
        onClose={() => {
          setDeleteDialog(false);
        }}
        loading={false}
      />
    </>
  );
};

export default ImageUploader;
