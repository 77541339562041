import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from "react";
import Slide from "@material-ui/core/Slide";
import sizes from "constants/sizes";

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const useStyles = makeStyles((theme) => ({
  cardStyle: {
    border: `2px solid ${theme.palette.primary.backgroundSecondary}`,
    fontSize: "1rem",
    position: "relative",
    width: "330px",
    height: "260px",
    transition: "all 1s",

    zIndex: "1",
    flex: "0 0 calc(100% - 20px)",

    margin: "20px 0",
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 calc(50% - 20px)",
    },
    [theme.breakpoints.up("md")]: {
      flex: "0 0 calc(33% - 20px)",
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 calc(25% - 20px)",
    },
    [theme.breakpoints.up("xl")]: {
      flex: "0 0 calc(16.66% - 20px)",
    },
  },
  downloadLink: {
    textDecoration: "underline",
    color: "blue",
    cursor: "pointer",
    '&:hover': {
      color: 'purple'
    }
  },

  cardActionArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: "5px",
  },
  cardTitle: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontWeight: "bold",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    textAlign: "center",
    display: "block",
    padding: "2rem",
    color: theme.palette.primary.backgroundSecondary,
    height: "1.8rem",
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));
export const Title = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
`;

export const Title2 = styled.h1`
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;

  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: none;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  display: block;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
`;

export const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (min-width: ${sizes.MD}px) {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 1000px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
