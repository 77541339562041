/** @format */

import { Card, CardActionArea, Zoom, Typography } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { useTheme } from "@material-ui/core/styles";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useStyles } from "./styles";
import { Tooltip } from "@mui/material";

const InvoiceVendorCard = ({
    setEditVendor,
    invoiceVendor,
    toggleSure,
    disabled,
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Zoom in={true} timeout={1000}>
            <Card className={classes.cardStyle}>
                {disabled ? (
                    <Tooltip title="Demo bolt esetén a számlakibocsátó szolgáltatás nem törölhető">
                        <Delete
                            className={classes.disabled}
                            onClick={() => {}}
                        />
                    </Tooltip>
                ) : (
                    <Delete className={classes.delete} onClick={toggleSure} />
                )}

                <CardActionArea
                    className={classes.cardActionArea}
                    onClick={() => {
                        setEditVendor(invoiceVendor);
                    }}
                >
                    <ReceiptLongIcon
                        style={{
                            color: theme.palette.primary.backgroundSecondary,
                            fontSize: "5rem",
                        }}
                    />
                    <Typography className={classes.cardTitle}>
                        {invoiceVendor?.name}
                    </Typography>
                </CardActionArea>
            </Card>
        </Zoom>
    );
};

export default InvoiceVendorCard;
