/** @format */

import { useGetQueryString } from "hooks/useGetQueryString";
import React from "react";
import { useState } from "react";
import QueryOptions from "./config/QueryOptions";
import AdminQueryOptions from "./config/AdminQueryOptions";
import QueryParam from "./QueryParam/QueryParam";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
    Wrapper,
    OptionsWrapper,
    ActionsWrapper,
    AddWrapper,
    TitleWrapper,
    SearchWrapper,
    Option,
    useStyles,
} from "./styles";
import { Button, Tooltip, Typography, useTheme } from "@material-ui/core";
import { useEffect } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Info } from "@material-ui/icons";
const ChartQueryBuilder = ({
    children,
    siteType,
    chartName,
    accountingData,
    disabled,
    refetch,
}) => {
    const theme = useTheme();
    const [search, setSearch] = useState(localStorage.getItem(chartName));

    const resetQueryString = () => {
        localStorage.setItem(chartName, "");
        setSearch(localStorage.getItem(chartName));
        refetch();
        setOptions(
            getInitialOptions({
                options:
                    siteType === "admin" ? AdminQueryOptions : QueryOptions,
            })
        );
    };

    const classes = useStyles(theme);
    const { getQueryString } = useGetQueryString();

    //TODO check value validity
    const getInitialOptions = ({ options }) => {
        return options?.map((o) => {
            return { ...o, used: false, value: "" };
        });
    };

    const [options, setOptions] = useState(
        getInitialOptions({
            options: siteType === "admin" ? AdminQueryOptions : QueryOptions,
        })
    );
    const [currentQueryString, setCurrentQueryString] = useState(
        localStorage.getItem(chartName)
    );
    const [isAddDisabled, setIsAddDisabled] = useState(false);
    const [isAddOptionOpen, setIsAddOptionOpen] = useState(false);

    const toggleIsAddOptionOpen = () => setIsAddOptionOpen(!isAddOptionOpen);

    useEffect(() => {
        setCurrentQueryString(getQueryString(options));
    }, [options]);

    useEffect(() => {
        if (search !== currentQueryString) {
            localStorage.setItem(`${chartName}`, currentQueryString);
        }
        refetch();
        setSearch(localStorage.getItem(chartName));
    }, [currentQueryString]);

    const setOption = (option) => {
        setOptions(
            options.map((currentOption) => {
                if (currentOption.name === option.name) {
                    return option;
                } else {
                    return currentOption;
                }
            })
        );
    };

    const disableOption = (option) => {
        setOptions(
            options.map((currentOption) => {
                if (currentOption.name === option.name) {
                    currentOption.used = false;
                    currentOption.value = "";
                }
                return currentOption;
            })
        );
    };
    const enableOption = (name) => {
        setOptions(
            options.map((currentOption) => {
                if (currentOption.name === name) {
                    currentOption.used = true;
                }
                return currentOption;
            })
        );
        setIsAddOptionOpen(false);
    };

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Wrapper className={classes.groupWrapper}>
                <SearchWrapper
                    backgroundColor={
                        siteType === "group"
                            ? "transparent"
                            : theme.palette.primary.backgroundSecondary
                    }
                >
                    <TitleWrapper>
                        <Typography
                            component="div"
                            variant="h2"
                            style={{
                                color:
                                    siteType === "group"
                                        ? theme.palette.primary
                                              .backgroundSecondary
                                        : theme.palette.primary.contrastText,
                                fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
                                fontSize: "2.5rem",
                            }}
                        >
                            Keresés
                            <Tooltip title={'Alapértelmezetten az utolsó adatot tartalmazó hét információit jelenítjük meg!'}>
                              <Info style={{fontSize: '20px', marginBottom: '1rem'}}></Info>  
                            </Tooltip>
                            
                        </Typography>
                    </TitleWrapper>
                    {options.map(
                        (option) =>
                            option.used &&
                            option.type !== "pagination" && (
                                <QueryParam
                                    key={`${option.name}_queryparam`}
                                    option={{ ...option }}
                                    setOption={setOption}
                                    disableOption={() => disableOption(option)}
                                    setIsAddDisabled={setIsAddDisabled}
                                    accountingData={accountingData}
                                />
                            )
                    )}
                    <AddWrapper>
                        <Tooltip title={"Keresési szűrő hozzáadása"}>
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor: isAddOptionOpen
                                        ? theme.palette.primary.main
                                        : theme.palette.primary
                                              .backgroundContent,
                                    color: isAddOptionOpen
                                        ? theme.palette.primary
                                              .backgroundContent
                                        : theme.palette.primary.main,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                }}
                                onClick={toggleIsAddOptionOpen}
                                disabled={isAddDisabled ||  disabled}
                            >
                                {isAddOptionOpen ? <RemoveIcon /> : <AddIcon />}
                                Szűrő hozzáadása
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Keresés alaphelyzetbe állítása"}>
                            <Button
                                style={{
                                    backgroundColor:
                                        theme.palette.primary.backgroundContent,
                                    color: theme.palette.primary.main,
                                    margin: 0,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    resetQueryString();
                                }}
                            >
                                <RestartAltIcon
                                    style={{ fontSize: "1.5rem" }}
                                />
                                Szűrők törlése
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Tételek frissítése"}>
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor:
                                        theme.palette.primary.backgroundContent,
                                    color: theme.palette.primary.main,
                                    marginTop: 0,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                    transition: "background 300ms",
                                    // opacity: !deliveries?.find(
                                    //   (delivery) => delivery.selected
                                    // )
                                    //   ? 0.3
                                    //   : 1,
                                }}
                                onClick={() => refetch()}
                            >
                                <RefreshIcon style={{ fontSize: "1.5rem" }} />
                                {"Adatok frissítése"}
                            </Button>
                        </Tooltip>
                        {isAddOptionOpen && (
                            <OptionsWrapper
                                backgroundColor={
                                    theme.palette.primary.backgroundContent
                                }
                                borderColor={theme.palette.primary.main}
                            >
                                {options.map(
                                    (option) =>
                                        !option.used &&
                                        option.type !== "pagination" && (
                                            <Option
                                                backgroundColor={
                                                    theme.palette.primary
                                                        .backgroundContent
                                                }
                                                color={
                                                    theme.palette.primary.main
                                                }
                                                fontFamily={
                                                    theme.typography.main
                                                }
                                                key={`${option.name}_option`}
                                                onClick={(e) =>
                                                    enableOption(option.name)
                                                }
                                            >
                                                {option.displayName}
                                            </Option>
                                        )
                                )}
                            </OptionsWrapper>
                        )}
                    </AddWrapper>
                </SearchWrapper>
                <ActionsWrapper
                    backgroundColor={theme.palette.primary.backgroundSecondary}
                >
                    <div>
                        {
                            // Placeholder.
                        }
                    </div>
                </ActionsWrapper>
                {children}
            </Wrapper>
        </div>
    );
};

export default ChartQueryBuilder;
