
const queryOptions = [
  {
    name: "orderId",
    displayName: "Rendelési azonosító",
    type: "string",
    used: false,
    value: "",
  },
  {
    name: "shopId",
    displayName: "Bolt azonosító",
    type: "string",
    used: false,
    value: "",
  },
  {
    name: "createdAt",
    displayName: "Rendelés dátuma",
    type: "date",
    used: false,
    value: "",
  },
  {
    name: "paymentStatus",
    displayName: "Státusz",
    type: "select",
    values: [
      "RECEIVED",
      "INITIATED",
      "CONFIRMED",
      "PAID",
      "REJECTED",
      "CANCELLED",
      "REFUND",
      "TIMEOUT",
    ],
    displayedValues: [
      "FIZETÉSRE VÁR",
      "INITIATED",
      "FIZETÉS MEGKEZDVE",
      "FIZETVE",
      "VISSZAUTASÍTVA",
      "MEGSZAKÍTVA",
      "VISSZATÉRÍTÉS",
      "IDŐTÚLLÉPÉS",
    ],
    used: false,
    value: "",
  },
  {
    name: "paymentVendorType",
    displayName: "Fizetési vendor",
    type: "select",
    values: [
      "TRANSFER",
      "OTP_SIMPLE",
      "DEMO_OTP_SIMPLE",
      "PAYPAL",
      "BARION"
    ],
    displayedValues: [
      "Átutalás",
      "OTP SimplePay",
      "Demo OTP SimplePay",
      "PayPal",
      "Barion"
    ],
    used: false,
    value: "",
  },
  {
    name: "sort",
    displayName: "Rendezési szempont",
    type: "key-value",
    keys: [
      { name: "orderId", displayName: "Rendelési azonosító" },
      { name: "shopId", displayName: "Bolt azonosító" },
      { name: "status", displayName: "Státusz" },
      { name: "createdAt", displayName: "Rendelési dátum" },
    ],
    values: [
      { name: "asc", displayName: "Növekvő" },
      { name: "desc", displayName: "Csökkenő" },
    ],
    used: false,
    value: "",
  },
  {
    name: "pagination",
    displayName: "",
    type: "pagination",
    used: true,
    value: "",
  },
];

export default queryOptions;
