import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import Zoom from '@material-ui/core/Zoom';

import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { Tooltip } from '@mui/material';

const AddGroupCard = ({ toggleCreateModal, isSchool, disabled }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Zoom in={true} timeout={1000}>
      <Card className={classes.cardStyle}>
        <Tooltip
          title={
            isSchool
              ? disabled
                ? 'Elérted a demo boltod galéria limitjét. Ha több iskolát szeretnél létrehozni, élesítsd boltodat!'
                : 'Iskola Hozzáadása'
              : disabled
              ? 'Elérted a demo boltod galéria limitjét. Ha több osztályt szeretnél létrehozni, élesítsd boltodat!'
              : 'Osztály hozzáadása'
          }>
          <CardActionArea
            className={classes.cardActionArea}
            onClick={
              disabled
                ? () => {}
                : () => {
                    toggleCreateModal();
                  }
            }>
            <AddCircleIcon
              style={{
                color: theme.palette.primary.backgroundSecondary,
                fontSize: '5rem'
              }}
            />
            <Typography className={classes.cardTitle}>
              {isSchool ? 'Iskola Hozzáadása' : ' Osztály Létrehozása'}
            </Typography>
          </CardActionArea>
        </Tooltip>
      </Card>
    </Zoom>
  );
};

export default AddGroupCard;
