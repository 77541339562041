import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Zoom from '@material-ui/core/Zoom';
import 'react-datepicker/dist/react-datepicker.css';
import { AllButton, OrderListWrapper, useStyles } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Tooltip } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import { useRef } from 'react';
import QueryBuilder from 'components/common/QueryBuilder/QueryBuilder';
import { usePrintableGroupLogic } from './hooks/usePrintableGroupLogic';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { useModifierApi } from 'pages/PhotoAdmin/AdminMenu/Templates/hooks/useModifierApi';
import { usePrinterApi } from 'pages/PrinterAdmin/hooks/usePrinterApi';
import { useSelector } from 'react-redux';

const Printing = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { getModifiers, modifiers } = useModifierApi();
  const { search } = useLocation();

  const [showAll, setShowAll] = useState(false);
  const [isPrintingDoneAll, setIsPrintingDoneAll] = useState(false);
  const [isPrintedAll, setIsPrintedAll] = useState(false);

  const [isDeliveryType, setIsDeliveryType] = useState(false);

  const [open, setOpen] = useState();

  const [allSelected, setAllSelected] = useState(false);

  const { fetchGroups, printGroup, groups, setGroups, loading, groupsLength } = usePrintableGroupLogic();

  const { printGroupFinal } = usePrinterApi();

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);

  const toggleGroup = (groups, currentGroup) => {
    const newGroups = groups.map((group) => {
      if (group.id === currentGroup.id) {
        return { ...group, selected: !group.selected };
      }
      return group;
    });
    setGroups(newGroups);
  };
  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleSelectAll = (groups, e) => {
    setAllSelected(e.target.checked);
    const newGroups = groups.map((group) => ({
      ...group,
      selected: e.target.checked
    }));
    setGroups(newGroups);
  };

  const handlePrint = async () => {
    let promises = [];
    groups?.forEach(async (group) => {
      if (group.selected) {
        let newGroup = { ...group };
        delete newGroup.selected;
        let promise = printGroup({
          group: { ...newGroup, isPrinted: true }
        });
        promises.push(promise);
      }
    });
    await Promise.all(promises);
    let printer = '';
    if (search.includes('isActive=ISPRINTED')) {
      printer = search.replace('isActive=ISPRINTED', 'isPrinted=true&isPrintingDone=false');
    } else if (search.includes('isActive=ISPRINTINGDONE')) {
      printer = search.replace('isActive=ISPRINTINGDONE', 'isPrintingDone=true');
    } else if (search.includes('isActive=ISNOTPRINTED')) {
      printer = search.replace('isActive=ISNOTPRINTED', 'isPrinted=false');
    }
    await fetchGroups({ queryString: printer === '' ? search : printer });
  };
  const handlePrintEnding = async () => {
    let promises = [];
    groups?.forEach(async (group) => {
      if (group.selected) {
        let newBody = { ...group };
        delete newBody.selected;
        newBody.isPrintingDone = true;
        let promise = printGroupFinal({
          group: { ...newBody }
        });
        promises.push(promise);
      }
    });
    await Promise.all(promises);
    refresh();
  };

  const refresh = () => {
    let printer = '';
    if (search.includes('isActive=ISPRINTED')) {
      printer = search.replace('isActive=ISPRINTED', 'isPrinted=true&isPrintingDone=false');
    } else if (search.includes('isActive=ISPRINTINGDONE')) {
      printer = search.replace('isActive=ISPRINTINGDONE', 'isPrintingDone=true');
    } else if (search.includes('isActive=ISNOTPRINTED')) {
      printer = search.replace('isActive=ISNOTPRINTED', 'isPrinted=false');
    }
    fetchGroups({ queryString: printer === '' ? search : printer });
  };

  const checkSelfPrint = () => {
    let isSelf = false;
    modifiers?.forEach((el) => {
      if (el?.deliveryTypeDTO?.includes('LELKES')) {
        isSelf = true;
      }
    });
    setIsDeliveryType(isSelf);
  };

  const linkRef = useRef(null);

  useEffect(() => {
    let printer = '';
    if (search.includes('isActive=ISPRINTED')) {
      printer = search.replace('isActive=ISPRINTED', 'isPrinted=true&isPrintingDone=false');
    } else if (search.includes('isActive=ISPRINTINGDONE')) {
      printer = search.replace('isActive=ISPRINTINGDONE', 'isPrintingDone=true');
    } else if (search.includes('isActive=ISNOTPRINTED')) {
      printer = search.replace('isActive=ISNOTPRINTED', 'isPrinted=false');
    }
    fetchGroups({ queryString: printer === '' ? search : printer });
    let queryParams = new URLSearchParams(printer === '' ? search : printer);

    if (queryParams.get('size') !== groupsLength) {
      setShowAll(false);
    }
  }, [search]);

  useEffect(() => {
    getModifiers();
    refresh();
  }, []);

  useEffect(() => {
    checkSelfPrint();
  }, [modifiers]);

  useEffect(() => {
    setIsPrintingDoneAll(groups?.find((el) => el.isPrintingDone === true) ? true : false);
    setIsPrintedAll(groups?.find((el) => el.isPrinted === true) ? true : false);
  }, [groups]);

  const resetQueryString = () => {
    navigate('/orders');
  };
  return (
    <Container maxWidth={false} className={classes.root}>
      <CssBaseline />
      <a ref={linkRef} />
      <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumb}>
        <Typography variant='body1'>Nyomtatás</Typography>
      </Breadcrumbs>
      <Zoom in={true} timeout={1000}>
        <Container maxWidth={false} className={classes.container}>
          <QueryBuilder
            itemsLength={groupsLength}
            siteType={'admin'}
            showAll={showAll}
            reset={resetQueryString}
            refresh={refresh}>
            <table className={classes.headerTop}>
              <tbody>
                <tr width='100%' className={classes.searchBar}>
                  <td
                    // className={classes.hideXs}
                    style={{
                      color: 'whitesmoke',
                      textAlign: 'center'
                    }}>
                    <Tooltip title={'Kijelölt iskolák és azok által tartalmazott galériák küldése nyomtatásra'}>
                      <Button
                        variant='outlined'
                        style={{
                          backgroundColor:
                            groups?.find((group) => group.selected) && theme.palette.primary.backgroundContent,
                          color: theme.palette.primary.main,
                          marginTop: 0,
                          border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                          transition: 'background 300ms'
                          // opacity: !deliveries?.find(
                          //   (delivery) => delivery.selected
                          // )
                          //   ? 0.3
                          //   : 1,
                        }}
                        disabled={
                          !groups?.find((group) => group.selected) &&
                          isPrintedAll &&
                          !isPrintingDoneAll &&
                          isPrintedAll &&
                          isPrintingDoneAll
                        }
                        onClick={() => setOpen('printConfirm')}>
                        <PrintIcon />
                        Küldés nyomtatásra
                      </Button>
                    </Tooltip>
                    {isDeliveryType ? (
                      <Tooltip title={'Kijelölt iskolák és azok által tartalmazott galériák küldése nyomtatásra'}>
                        <Button
                          variant='outlined'
                          style={{
                            backgroundColor:
                              groups?.find((group) => group.selected) && theme.palette.primary.backgroundContent,
                            color: theme.palette.primary.main,
                            margin: 0,
                            border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                            transition: 'background 300ms'
                          }}
                          disabled={
                            !groups?.find((group) => group.selected) &&
                            !isPrintedAll &&
                            !isPrintingDoneAll &&
                            isPrintedAll &&
                            isPrintingDoneAll
                          }
                          onClick={() => setOpen('printEnding')}>
                          <PrintIcon />
                          Nyomtatás véglegesítése
                        </Button>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                    <ConfirmDialog
                      open={open === 'printConfirm'}
                      title='Biztosan nyomtatóra akarod küldeni a kijelölt iskolákat? A kijelölt iskolákban lévő összes galéria nyomtatásra kerül, azokból vásárolni már csupán digitális formátumú képeket lehet és a hozzájuk tartozó nyomtatandó rendelések beérkeznek hozzánk. Ezt követően, minél hamarabb megkezdjük ezek nyomtatását.'
                      functionButtonText='Nyomtatás'
                      functionButtonColor={theme.palette.primary.success}
                      demo={!!user?.ownedShops?.find((shop) => shop.id == shopId)?.isDemo}
                      onOk={async () => {
                        setOpen(null);
                        await handlePrint();
                        setAllSelected(false);
                      }}
                      onClose={() => setOpen(null)}
                      closeButtonText='Mégsem'
                      multipleButtons
                    />
                    <ConfirmDialog
                      open={open === 'printEnding'}
                      title='Biztosan Véglegesíted a nyomtatást? Ezáltal a fotósoknak is visszajelzést küldünk, hogy a kijelölt iskolák nyomtatása megtörtént!'
                      functionButtonText='Nyomtatás véglegesítése'
                      functionButtonColor={theme.palette.primary.success}
                      onOk={async () => {
                        setOpen(null);
                        await handlePrintEnding();
                        setAllSelected(false);
                      }}
                      onClose={() => setOpen(null)}
                      closeButtonText='Mégsem'
                      multipleButtons
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={classes.header}>
              <thead>
                <tr width='100%'>
                  <td className={classes.heading} width='10%'>
                    <Checkbox
                      style={{ color: 'whitesmoke' }}
                      checked={allSelected}
                      onChange={(e) => handleSelectAll(groups, e)}></Checkbox>
                  </td>
                  <td className={classes.hideXs} style={{ width: '5%' }}>
                    <Typography className={classes.heading}>#</Typography>
                  </td>
                  <td className={classes.hideXs} style={{ width: '10%' }}>
                    <Typography className={classes.heading}>Azonosító</Typography>
                  </td>
                  <td className={classes.heading}>
                    <Typography className={classes.heading}>Iskola neve</Typography>
                  </td>
                  <td className={classes.heading}>
                    <Typography className={classes.heading}>Létrehozás dátuma</Typography>
                  </td>
                  <td className={classes.hideXs}>
                    <Typography className={classes.heading}>Státusz</Typography>
                  </td>
                  <td className={classes.tableCell}>
                    <Typography className={classes.heading}>Nyomtatás állapota</Typography>
                  </td>
                </tr>
              </thead>
            </table>
            <OrderListWrapper>
              <table className={classes.listitemtable}>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <LoadIndicator color={theme.palette.primary.light} height={800} />
                      </td>
                    </tr>
                  ) : groups?.length > 0 ? (
                    groups?.map((group, idx) => (
                      <tr
                        className={classes.listItem}
                        width='100%'
                        key={group.id}
                        onClick={() => {
                          toggleGroup(groups, group);
                        }}>
                        <td className={classes.tableCell} width='10%'>
                          <Checkbox
                            color='primary'
                            checked={group?.selected}
                            onChange={(e) => toggleGroup(groups, group)}></Checkbox>
                        </td>
                        <td className={classes.hideXs} style={{ width: '5%' }}>
                          <Typography className={classes.tableCell}>{idx + 1}</Typography>
                        </td>
                        <td className={classes.hideXs} style={{ width: '10%' }}>
                          <Typography className={classes.tableCell}>{group?.id}</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography className={classes.tableCell}>{group?.name}</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          {group?.createdAt && (
                            <Typography className={classes.tableCell}>
                              {new Date(group?.createdAt).toLocaleDateString('hu-HU', {
                                hour: '2-digit',
                                minute: '2-digit',
                                seconds: '2-digit'
                              })}
                            </Typography>
                          )}
                        </td>

                        <td className={classes.hideXs}>
                          <Typography
                            style={{
                              fontFamily: theme.typography.main,
                              color: group?.isActive ? 'rgb(25,109,235)' : theme.palette.primary.error
                            }}>
                            {group?.isActive ? 'Aktív' : 'Inaktív'}
                          </Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography
                            style={{
                              fontFamily: theme.typography.main,
                              color: !group?.isPrinted
                                ? 'black'
                                : group?.isPrinted && !group?.isPrintingDone
                                ? 'rgb(204, 204, 0 )'
                                : theme.palette.primary.success,
                              fontWeight: group?.isPrinted && group?.isPrintingDone && 'bold'
                            }}>
                            {!group?.isPrinted
                              ? 'Nyomtatható'
                              : group?.isPrinted && !group?.isPrintingDone
                              ? 'Nyomtatásra vár'
                              : 'Nyomtatva'}
                          </Typography>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      style={{
                        margin: '0.5rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                      <td>
                        <Typography variant='h5' style={{ color: theme?.palette?.primary?.light }}>
                          Nincs nyomtatható galéria.
                        </Typography>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </OrderListWrapper>
          </QueryBuilder>

          <AllButton
            className={classes.allBtn}
            onClick={() => {
              handleShowAll();
            }}>
            {showAll ? 'Kevesebb nyomtatandó galéria' : 'Összes nyomtatandó galéria'}
          </AllButton>
        </Container>
      </Zoom>
    </Container>
  );
};

export default Printing;
