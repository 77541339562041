import * as yup from "yup";

const contactValidationSchema = yup.object({
  contactFirstName: yup
    .string("Adja meg keresztnevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "Helytelen formátum"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  contactLastName: yup
    .string("Adja meg vezetéknevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "Helytelen formátum"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  username: yup
    .string("Adja meg")
    .max(50, "Kérjük legfeljebb 50 karakter hosszú felhasználó nevet adjon meg")
    .required("Szükséges mező"),

  password: yup
    .string("Adja meg jelszavát")
    .min(8, "Legalább 8 karakter hosszú jelszót adjon meg!")
    .required("Szükséges mező"),
  passwordConfirm: yup
    .string("Erősítse meg jelszavát")
    .required("Szükséges mező")
    .when("password", (password) => {
      if (password) {
        return yup
          .string("Erősítse meg jelszavát")
          .required("Szükséges mező")
          .test(
            "do-the-password-match",
            "A két jelszó nem egyezik!",
            function (passwordConfirm) {
              return password === passwordConfirm;
            }
          );
      }
    }),

  contactEmail: yup
    .string("Adja meg email címét")
    .email("Kérem adjon meg egy valós email címet")
    .required("Szükséges mező"),
  emailConfirm: yup
    .string("Erősítse meg email címét")
    .email("Kérem adjon meg egy valós email címet")
    .required("Szükséges mező")
    .when("contactEmail", (contactEmail) => {
      if (contactEmail) {
        return yup
          .string("Erősítse meg email címét")
          .email("Kérem adjon meg egy valós email címet")
          .required("Szükséges mező")
          .test(
            "do-the-email-match",
            "A két email cím nem egyezik!",
            function (emailConfirm) {
              return contactEmail === emailConfirm;
            }
          );
      }
    }),
  contactPhone: yup
    .string("Adja meg a telefonszámát")
    .matches(/^[0-9]{8}|[0-9]{9}$/, "Helyes formátum: 301234567 / 30123456")
    .max(9, "Nem lehet hosszabb 9 karakternél")
    .required("Szükséges mező"),
  contactCountry: yup
    .string("Adja meg országát")
    .min(1, "Adja meg országát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  contactZipCode: yup
    .number("Adja meg városának irányítószámát")
    .typeError("Kizárólag számok megengedettek")
    .min(999, "Irányítószáma egy 4 jegyű szám")
    .max(9999, "Irányítószáma egy 4 jegyű szám")
    .required("Szükséges mező"),
  contactCity: yup
    .string("Adja meg városát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  contactStreetAndNumber: yup
    .string("Adja meg a közterület nevét, és házszámát")
    .min(1, "Adja meg a közterület nevét, és házszámát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
});

export default contactValidationSchema;
