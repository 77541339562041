/** @format */

import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useNavigate } from "react-router-dom";
import HeaderData from "config/HeaderData";
import AuthService from "services/AuthService";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CircularProgress, Menu, MenuItem, Tooltip } from "@material-ui/core";
import StoreIcon from "@mui/icons-material/Store";
import { useEffect, useState } from "react";
import { getApi } from "api/axios";
import { errorToast, successToast } from "components/common/Toast/Toast";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import { useUserApi } from "pages/Register/hooks/useUserApi";

const NavbarMenu = ({ match, user, ownShop }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();

    const shopId = useSelector(
        (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
    );

    const {getCurrentUser} = useUserApi();
    const [profileAnchor, setProfileAnchor] = useState(null);
    const [shopAnchor, setShopAnchor] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleProfileMenu = (e) => {
        setProfileAnchor(e.currentTarget);
    };
    const handleShopMenu = (e) => {
        setShopAnchor(e.currentTarget);
    };

    const handleClose = () => {
        setShopAnchor(null);
        setProfileAnchor(null);
    };

    const launchShop = async () => {
        setLoading(true);
        try {
            const res = await (
                await getApi({
                    baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                })
            ).patch(`/admin/shops/${shopId}/clear`);
            if (res.status >= 200 && res.status < 300) {
                successToast(
                    `Sikeresen élesítette boltját, most már teljeskörűen használhatja az Iskolaévkönyv összes funkcióját, értékesítheti fényképeit!`
                );
                setOpen(false);
                getCurrentUser();
            }
            setLoading(false);
        } catch (e) {
            errorToast(
                `Sikertelen bolt élesítés! Kérjük próbálja újra később!`
            );
            console.log(e);
            setLoading(false);
        }
    };

    const handleLogout = () => {
        //dispatch(logout());
        AuthService.doLogout();
    };

    useEffect(() => {
        console.log(
            user?.ownedShops?.find((shop) => shop.id == shopId)?.isDemo
        );
    }, [shopId]);

    return (
        <List component="nav" className={classes.bigList}>
            {AuthService.isLoggedIn() && (
                <>
                    {user?.groups?.find((group) => group === "users") &&
                        user?.ownedShops?.find((shop) => shop.id == shopId)
                            ?.isDemo === true && (
                            <Tooltip title={"Élesítés"}>
                                <ListItem
                                    button
                                    onClick={(e) => {
                                        setOpen(true);
                                    }}
                                    className={classes.listItem}
                                >
                                    Demo
                                </ListItem>
                            </Tooltip>
                        )}
                    {(user?.groups?.find((group) => group === "dt_admin") ||
                        user?.groups?.find(
                            (group) => group === "printer_admin"
                        )) && (
                        <Tooltip title={"Kijelentkezés"}>
                            <ListItem
                                button
                                onClick={(e) => {
                                    handleLogout();
                                }}
                                className={classes.listItem}
                            >
                                {HeaderData.logoutOption.icon}
                            </ListItem>
                        </Tooltip>
                    )}

                    {user?.groups?.find((group) => group === "users") && (
                        <>
                            <Tooltip
                                title={
                                    user?.ownedShops?.length !== 1
                                        ? "Boltok kezelése"
                                        : "Bolt kezelése"
                                }
                            >
                                <ListItem
                                    button
                                    onClick={(e) => {
                                        handleShopMenu(e);

                                        e.stopPropagation();
                                    }}
                                    className={classes.listItem}
                                >
                                    <StoreIcon
                                        classes={{
                                            primary: classes.bigListItemText,
                                        }}
                                    />
                                </ListItem>
                            </Tooltip>
                            <Menu
                                id="shop-appbar"
                                anchorEl={shopAnchor}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(shopAnchor)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        navigate(`/shops`);
                                        handleClose();
                                    }}
                                    disabled={
                                        user?.ownedShops?.length === 1 &&
                                        user?.maintainedShops?.length === 0
                                    }
                                >
                                    Boltok
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate(
                                            `/shops/${shopId}/administration/orders`
                                        );
                                        handleClose();
                                    }}
                                    disabled={shopId === -1}
                                >
                                    Bolt adminisztráció
                                </MenuItem>
                                {ownShop ? (
                                    <MenuItem
                                        onClick={() => {
                                            navigate(
                                                `/shops/${shopId}/settings/roles`
                                            );
                                            handleClose();
                                        }}
                                    >
                                        Bolt beállítások
                                    </MenuItem>
                                ) : shopId !== -1 &&
                                  !ownShop &&
                                  user?.maintainedShops
                                      ?.find((shop) => shop.shop.id === shopId)
                                      ?.scopes?.includes("VIEW_SHOP") ? (
                                    <MenuItem
                                        onClick={() => {
                                            navigate(
                                                `/shops/${shopId}/settings/roles`
                                            );
                                            handleClose();
                                        }}
                                    >
                                        Bolt beállítások
                                    </MenuItem>
                                ) : shopId !== -1 &&
                                  !ownShop &&
                                  !user?.maintainedShops
                                      ?.find((shop) => shop.shop.id === shopId)
                                      ?.scopes?.includes("VIEW_SHOP") &&
                                  user?.maintainedShops
                                      ?.find((shop) => shop.shop.id === shopId)
                                      ?.scopes?.includes(
                                          "VIEW_INVOICE_VENDORS"
                                      ) ? (
                                    <MenuItem
                                        onClick={() => {
                                            navigate(
                                                `/shops/${shopId}/settings/invoicing`
                                            );
                                            handleClose();
                                        }}
                                    >
                                        Bolt beállítások
                                    </MenuItem>
                                ) : shopId === -1 ? (
                                    <MenuItem onClick={() => {}} disabled>
                                        Bolt beállítások
                                    </MenuItem>
                                ) : (
                                    <Tooltip
                                        title={
                                            "A bolt beállításait csak a megfelelő jogosultságok birtokában érheti el"
                                        }
                                    >
                                        <MenuItem
                                            onClick={() => {}}
                                            style={{
                                                color: "rgba(144,144,144, 0.8)",
                                            }}
                                        >
                                            Bolt beállítások
                                        </MenuItem>
                                    </Tooltip>
                                )}
                            </Menu>
                            <Tooltip title={"Felhasználói fiók"}>
                                <ListItem
                                    button
                                    onClick={(e) => {
                                        handleProfileMenu(e);
                                    }}
                                    className={classes.listItem}
                                >
                                    <AccountCircleIcon
                                        classes={{
                                            primary: classes.bigListItemText,
                                        }}
                                    />
                                </ListItem>
                            </Tooltip>
                            <Menu
                                id="menu-appbar"
                                anchorEl={profileAnchor}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(profileAnchor)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        navigate(`/settings/profile`);
                                        handleClose();
                                    }}
                                >
                                    Profil beállítások
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleLogout();
                                        handleClose();
                                    }}
                                >
                                    Kijelentkezés
                                </MenuItem>
                            </Menu>{" "}
                        </>
                    )}
                </>
            )}
            <ConfirmDialog
                open={open}
                title={"Biztosan élesíteni szeretné boltját?"}
                text={"FIGYELEM!"}
                text2={
                    "Élesítéssel az összes tesztelési célra létrehozott rendelését és számlázási beállításait töröljük, ugyanakkor galériái megmaradnak, így azokat éles környezetben is használhatja."
                }
                functionButtonText={
                    loading ? (
                        <CircularProgress size={23} color="green" />
                    ) : (
                        "Élesítés"
                    )
                }
                functionButtonColor={"green"}
                closeButtonText={"Mégse"}
                onOk={() => {
                    launchShop();
                }}
                onClose={() => {
                    setOpen(false);
                }}
                multipleButtons
                disabled={loading}
            />
        </List>
    );
};

export default NavbarMenu;
