const queryOptions = [
    {
      name: "name",
      displayName: "Iskola neve",
      type: "schoolName",
      used: false,
      value: "",
    },
    {
      name: "shopId",
      displayName: "Bolt azonosító",
      type: "string",
      used: false,
      value: "",
    },
    {
      name: "createdAt",
      displayName: "Rendelés dátuma",
      type: "date",
      used: false,
      value: "",
    },
  ];
  
  export default queryOptions;
  