/** @format */

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Zoom from "@mui/material/Zoom";
import "react-datepicker/dist/react-datepicker.css";
import { Button, MenuItem, Select, Tooltip } from "@mui/material";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import { useRef } from "react";
import QueryBuilder from "components/common/QueryBuilder/QueryBuilder";
import NotificationDialog from "components/common/NotificationDialog/NotificationDialog";
import styles from "./payments.module.css";
import { usePaymentsLogic } from "./usePaymentsLogic";
import { ChangeCircle } from "@mui/icons-material";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";

const Payments = () => {
    const {
        fetchPayments,
        refresh,
        resetQueryString,
        showAll,
        handleShowAll,
        handleStatusTranslate,
        paymentData,
        setPaymentData,
        allSelected,
        setAllSelected,
        handlePaymentStateChange,
        open,
        setOpen,
        selectableStates,
        selectedState,
        setSelectedState
    } = usePaymentsLogic();

    const handleStatusStyle = (status) => {
        if (status === "RECEIVED") {
            return styles.shipping;
        }
        if (status === "INVOICED") {
            return styles.invoiced;
        }
        if (status === "CONFIRMED") {
            return styles.verified;
        }
        if (status === "PAID") {
            return styles.done;
        }
        if (status === "REJECTED") {
            return styles.rejected;
        }
        if (status === "REFUND") {
            return styles.rejected;
        }
        if (status === "CANCELLED") {
            return styles.rejected;
        }
        if (status === "TIMEOUT") {
            return styles.rejected;
        }
    };

    const linkRef = useRef(null);

    return (
        <Container maxWidth={false} className={styles.root}>
            <CssBaseline />
            <a ref={linkRef} />
            <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumb}>
                <Typography variant="body1">Rendelések</Typography>
            </Breadcrumbs>
            <Zoom in={true} timeout={1000}>
                <Container maxWidth={false} className={styles.container}>
                    <QueryBuilder
                        itemsLength={
                            fetchPayments?.data?.data?.page?.totalElements
                        }
                        siteType={"payment"}
                        showAll={showAll}
                        reset={resetQueryString}
                        refresh={refresh}
                    >
                        <table className={styles.headerTop}>
                            <tbody>
                                <tr width="100%" className={styles.searchBar}>
                                    <td
                                        className={styles.tableCell}
                                        style={{
                                            color: "whitesmoke",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                "Adott oldalon kijelölt fizetések státuszának FIZETVE státuszra történő módosítása"
                                            }
                                        >
                                            <div>
                                                <Button
                                                    variant="outlined"
                                                    disabled={
                                                        !paymentData?.find(
                                                            (pD) => pD.selected
                                                        )
                                                    }
                                                    style={{
                                                        color: "whitesmoke",
                                                        margin: 0,
                                                        border: `2px solid whitesmoke`,
                                                    }}
                                                    onClick={() =>
                                                        setOpen(true)
                                                    }
                                                >
                                                    <ChangeCircle />
                                                    Kijelöltek státuszának
                                                    módosítása
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    </td>
                                    <td
                                        className={styles.tableCell}
                                        style={{
                                            color: "whitesmoke",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Select
                                            id="language"
                                            name="language"
                                            value={selectedState}
                                            onChange={(e) => {
                                                setSelectedState(e.target.value);
                                            }}
                                            className={styles.state_selector}
                                        >
                                            {selectableStates?.map((state) => (
                                                <MenuItem
                                                    key={state}
                                                    value={state}
                                                >
                                                    {handleStatusTranslate(state)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className={styles.header}>
                            <thead>
                                <tr width="100%">
                                    <td style={{ width: "2%" }}>
                                        <input
                                            className={styles.heading}
                                            type="checkbox"
                                            checked={allSelected}
                                            onClick={() =>
                                                setAllSelected(!allSelected)
                                            }
                                        ></input>
                                    </td>
                                    <td className={styles.hideXs}>
                                        <Typography className={styles.heading}>
                                            Rendelési azonosító
                                        </Typography>
                                    </td>
                                    <td className={styles.hideMd}>
                                        <Typography className={styles.heading}>
                                            Bolt azonosító
                                        </Typography>
                                    </td>
                                    <td className={styles.heading}>
                                        <Typography className={styles.heading}>
                                            Ügyfél neve
                                        </Typography>
                                    </td>
                                    <td className={styles.heading}>
                                        <Typography className={styles.heading}>
                                            Rendelés dátuma
                                        </Typography>
                                    </td>
                                    <td className={styles.hideLg}>
                                        <Typography className={styles.heading}>
                                            E-mail cím
                                        </Typography>
                                    </td>
                                    <td className={styles.hideXs}>
                                        <Typography className={styles.heading}>
                                            Összeg
                                        </Typography>
                                    </td>
                                    <td className={styles.tableCell}>
                                        <Typography className={styles.heading}>
                                            Státusz
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                        <div className={styles.orderListWrapper}>
                            <table className={styles.listitemtable}>
                                <tbody>
                                    {fetchPayments.isLoading ? (
                                        <tr>
                                            <td>
                                                <LoadIndicator
                                                    color={"grey"}
                                                    height={800}
                                                />
                                            </td>
                                        </tr>
                                    ) : fetchPayments?.data.data?.page
                                          ?.totalElements > 0 ? (
                                        paymentData?.map((payment, idx) => (
                                            <tr
                                                className={
                                                    payment?.selected
                                                        ? styles.selectedListItem
                                                        : styles.listItem
                                                }
                                                width="100%"
                                                key={payment.id}
                                                onClick={() =>
                                                    setPaymentData(
                                                        paymentData?.map((pD) =>
                                                            pD.id === payment.id
                                                                ? {
                                                                      ...pD,
                                                                      selected:
                                                                          !pD.selected,
                                                                  }
                                                                : { ...pD }
                                                        )
                                                    )
                                                }
                                            >
                                                <td className={styles.check}>
                                                    <input
                                                        className={
                                                            styles.heading
                                                        }
                                                        type="checkbox"
                                                        checked={
                                                            payment?.selected
                                                        }
                                                    ></input>
                                                </td>
                                                <td className={styles.hideXs}>
                                                    <Typography
                                                        className={
                                                            styles.tableCell
                                                        }
                                                    >
                                                        {payment?.orderId}
                                                    </Typography>
                                                </td>
                                                <td className={styles.hideMd}>
                                                    <Typography
                                                        className={
                                                            styles.tableCell
                                                        }
                                                    >
                                                        {payment?.shopId}
                                                    </Typography>
                                                </td>
                                                <td
                                                    className={styles.tableCell}
                                                >
                                                    <Typography
                                                        className={
                                                            styles.tableCell
                                                        }
                                                    >
                                                        {
                                                            payment
                                                                ?.customerContact
                                                                ?.firstName
                                                        }{" "}
                                                        {
                                                            payment
                                                                ?.customerContact
                                                                ?.lastName
                                                        }
                                                    </Typography>
                                                </td>
                                                <td
                                                    className={styles.tableCell}
                                                >
                                                    {payment?.createdAt && (
                                                        <Typography
                                                            className={
                                                                styles.tableCell
                                                            }
                                                        >
                                                            {new Date(
                                                                payment?.createdAt
                                                            ).toLocaleDateString(
                                                                "hu-HU",
                                                                {
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                    seconds:
                                                                        "2-digit",
                                                                }
                                                            )}
                                                        </Typography>
                                                    )}
                                                </td>

                                                <td className={styles.hideLg}>
                                                    <Typography
                                                        className={
                                                            styles.tableCell
                                                        }
                                                    >
                                                        {
                                                            payment
                                                                ?.customerContact
                                                                ?.email
                                                        }
                                                    </Typography>
                                                </td>
                                                <td className={styles.hideXs}>
                                                    <Typography
                                                        className={
                                                            styles.tableCell
                                                        }
                                                    >
                                                        {
                                                            payment?.totalAmount
                                                                ?.grossAmount
                                                        }{" "}
                                                        Ft
                                                    </Typography>
                                                </td>
                                                <td
                                                    className={styles.tableCell}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontFamily:
                                                                "#0a2d4e",
                                                        }}
                                                        className={handleStatusStyle(
                                                            payment?.status
                                                        )}
                                                    >
                                                        {handleStatusTranslate(
                                                            payment?.status
                                                        )}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr
                                            style={{
                                                margin: "0.5rem",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <td>
                                                <Typography
                                                    variant="h5"
                                                    style={{ color: "grey" }}
                                                >
                                                    Nem érkezett még rendelés.
                                                </Typography>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </QueryBuilder>
                    <ConfirmDialog
                        title={
                            `Biztosan ${handleStatusTranslate(selectedState)} státuszúra akarod állítani a kiválasztott fizetési tételeket? NINCS visszaút! Vagy mégis?! ;)`
                        }
                        multipleButtons
                        functionButtonColor={"green"}
                        functionButtonText={"Igen"}
                        closeButtonText={"Mégsem"}
                        onClose={() => setOpen(false)}
                        open={open}
                        onOk={() => {
                            handlePaymentStateChange();
                            setOpen(false);
                        }}
                    />

                    {/* <Button
                        className={styles.allBtn}
                        onClick={() => {
                            handleShowAll();
                        }}
                    >
                        {showAll ? "Kevesebb fizetés" : "Összes fizetés"}
                    </Button> */}
                </Container>
            </Zoom>
            <NotificationDialog />
        </Container>
    );
};

export default Payments;
