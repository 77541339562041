import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  hideMd: {
    color: theme.palette.primary.textDark,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hideXs: {
    color: theme.palette.primary.textDark,
    "& .MuiTypography-root, & .MuiInputBase-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  showXs: {
    color: theme.palette.primary.textDark,
    "& .MuiTypography-root, & .MuiInputBase-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
