import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cardStyle: {
    border: `2px solid ${theme.palette.primary.backgroundSecondary}`,
    fontSize: "1rem",
    position: "relative",
    width: "100%",
    height: "292px",
    transition: "all 1s",

    zIndex: "1",
    flex: "0 0 calc(100% - 20px)",

    margin: "20px 10px",
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 calc(50% - 20px)",
    },
    [theme.breakpoints.up("md")]: {
      flex: "0 0 calc(50% - 20px)",
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 calc(33% - 20px)",
    },
    [theme.breakpoints.up("xl")]: {
      flex: "0 0 calc(25% - 20px)",
    },
  },

  cardActionArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: "5px",
  },
  cardTitle: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontWeight: "bold",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    textAlign: "center",
    fontSize: "0.9rem",
    display: "block",
    color: theme.palette.primary.backgroundSecondary,
    height: "1.8rem",
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  delete: {
    position: "absolute",
    top: "0.9rem",
    right: "1.2rem",
    zIndex: "2",
    cursor: "pointer",
    background: "white",
    borderRadius: "11px",
    transition: "250ms ease-in",
    "&:hover ": {
      background: "red",
      transition: "250ms ease-in",
    },
  },
  disabled: {
    position: "absolute",
    top: "0.9rem",
    right: "1.2rem",
    zIndex: "2",
    background: "lightgrey",
    borderRadius: "11px",
    transition: "250ms ease-in",    
  },
}));