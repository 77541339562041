/** @format */

import { getApi } from "api/axios";
import { useModifierSettingsAPI } from "components/PhotoAdminComponents/Settings/Invoicing/ModifierInvoiceVendors/hooks/useModifierSettingsAPI";
import { errorToast, successToast } from "components/common/Toast/Toast";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAlert } from "redux/slices/AlertSlice";

const { useState } = require("react");

export const useInvoicingSettingsAPI = () => {
    const shopId = useSelector(
        (state) => state.nonPersistedReducers.shopSliceReducer.shopId
    );
    const user = useSelector(
        (state) => state.nonPersistedReducers?.userSliceReducer?.currentUser
    );

    const [invoiceVendors, setinvoiceVendors] = useState(null);
    const [invoiceVendorsLoading, setInvoiceVendorsLoading] = useState(false);
    const [invoiceVendorsError, setInvoiceVendorsError] = useState(null);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (user?.ownedShops?.length > 0) {
            user?.ownedShops?.find((shop) => shop?.id == shopId)?.isDemo
                ? setDisabled(true)
                : setDisabled(false);
        }
    }, [user, shopId]);

    const dispatch = useDispatch();

    const { unsetModis, getModifiers, getModInvoiceVendors, modifiers } =
        useModifierSettingsAPI();

    const getInvoiceVendors = useCallback(async () => {
        try {
            setInvoiceVendorsLoading(true);
            setinvoiceVendors(null);
            const res = await (
                await getApi({
                    baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                })
            ).get(`/admin/shops/${shopId}/invoices/vendors`);
            if (res.status === 200) {
                setInvoiceVendorsLoading(false);
                setinvoiceVendors(res.data);
            }
        } catch (e) {
            setInvoiceVendorsLoading(false);
            setInvoiceVendorsError(e);
            console.log(e);
        }
    }, [shopId]);

    const [invoiceVendor, setinvoiceVendor] = useState(null);
    const [invoiceVendorLoading, setInvoiceVendorLoading] = useState(false);
    const [invoiceVendorError, setInvoiceVendorError] = useState(null);

    const postInvoiceVendor = useCallback(
        async ({ body, setOpen }) => {
            setInvoiceVendorLoading(true);
            try {
                setinvoiceVendor(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).post(`/admin/shops/${shopId}/invoices/vendors`, body);
                if (res.status >= 200 && res.status < 300) {
                    setInvoiceVendorLoading(false);
                    setinvoiceVendor(res.data);
                    getInvoiceVendors();
                    setOpen();
                    successToast("Sikeres létrehozás!");
                }
            } catch (e) {
                if (e?.response?.status === 403) {
                    errorToast(
                        "Számlázási kiszolgáló módosítására, csak a SZÁMLÁZÓ_VENDOROK_KEZELÉSE jogosultság birtokában van lehetőség!"
                    );
                } else {
                    errorToast("Sikertelen létrehozás, próbálja meg később!");
                }
                setInvoiceVendorLoading(false);
                setInvoiceVendorError(e);
                console.log(e);
            }
        },
        [shopId]
    );
    const putInvoiceVendor = useCallback(
        async ({ body, setOpen }) => {
            setInvoiceVendorLoading(true);
            try {
                setinvoiceVendor(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).put(`/admin/shops/${shopId}/invoices/vendors`, body);
                if (res.status === 200) {
                    setInvoiceVendorLoading(false);
                    setinvoiceVendor(res.data);
                    getInvoiceVendors();
                    setOpen();
                    successToast("Sikeres frissítés!");
                }
            } catch (e) {
                if (e?.response?.status === 403) {
                    errorToast(
                        "Számlázási kiszolgáló módosítására, csak a SZÁMLÁZÓ_VENDOROK_KEZELÉSE jogosultság birtokában van lehetőség!"
                    );
                } else {
                    errorToast("Sikertelen frissítés, próbálja meg később!");
                }
                setInvoiceVendorLoading(false);
                setInvoiceVendorError(e);
                console.log(e);
            }
        },
        [shopId]
    );
    const deleteInvoiceVendor = useCallback(
        async ({ vendorId, setOpen }) => {
            setInvoiceVendorLoading(true);
            try {
                setinvoiceVendor(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).delete(`/admin/shops/${shopId}/invoices/vendors/${vendorId}`);
                if (res.status === 200) {
                    setInvoiceVendorLoading(false);
                    setinvoiceVendor(res.data);
                    getInvoiceVendors();
                    setOpen();
                    successToast("Sikeres törlés");
                }
            } catch (e) {
                setInvoiceVendorLoading(false);
                setInvoiceVendorError(e);
                if (e?.response?.status === 403) {
                    errorToast(
                        "Számlázási kiszolgáló törlésére, csak a SZÁMLÁZÓ_VENDOROK_KEZELÉSE jogosultság birtokában van lehetőség!"
                    );
                } else {
                    errorToast("Sikertelen törlés, próbálja meg később!");
                }
            }
        },
        [shopId]
    );

    useEffect(() => {
        if (shopId !== -1) {
            getModifiers();
            getModInvoiceVendors();
        }
    }, [shopId]);

    useEffect(() => {
        if (invoiceVendors && invoiceVendors.length === 0) {
            dispatch(setAlert("no_vendor"));
        } else if (
            invoiceVendors &&
            invoiceVendors.length > 1 &&
            unsetModis.length > 0
        ) {
            dispatch(setAlert("set_modifiers"));
        } else {
            dispatch(setAlert(""));
        }
    }, [invoiceVendors, unsetModis]);

    return {
        getInvoiceVendors,
        postInvoiceVendor,
        putInvoiceVendor,
        deleteInvoiceVendor,
        invoiceVendor,
        invoiceVendorError,
        invoiceVendorLoading,
        invoiceVendors,
        invoiceVendorsError,
        invoiceVendorsLoading,
        modifiers,
        user,
        shopId,
        disabled,
    };
};
