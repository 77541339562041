import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const useStyles = makeStyles((theme) => ({
  formPart: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem'
    }
  },
  grid: {
    width: '100%',
    padding: '1.5rem 2rem'
  },
  input: {
    height: '30px',
    width: 'calc(100% - 1rem)',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'lightgrey',
    borderRadius: '20px',
    border: 'none',
    marginBottom: '0.5rem',
    outlineColor: 'black'
  },
  stepLabel: {
    borderRadius: '22px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '1rem',
    margin: '1rem 0 0 0'
  },
  info: {
    fontSize: '15px !important',
    color: 'grey'
  },
  label: {
    fontWeight: 400,
    fontSize: '1rem',
    margin: '1rem 0 0 0'
  },
  button: {
    textTransform: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '15px',
    height: '40px',
    width: '160px',
    border: 'none',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.backgroundSecondary,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.backgroundPrimary
    }
  },
  control: {
    padding: '0.1rem'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem'
    }
  }
}));
