/** @format */

//import clsx from 'clsx';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import Reports from "../../../components/SuperAdminComponents/Dashboard/Reports/Reports";
import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import ReportChart from "components/SuperAdminComponents/Dashboard/ReportChart/ReportChart";
import { useModifierApi } from "../PriceAdjustment/hooks/useModifierApi";
import { useEffect } from "react";

const Dashboard = () => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { modifiers, getModifiers } = useModifierApi();

    const accountingCharts = [
        { value: "bar", displayName: "Oszlopdiagram" },
        { value: "line", displayName: "Vonaldiagram" },
    ];
    const orderItemCharts = [
        { value: "bar", displayName: "Oszlopdiagram" },
        { value: "pie", displayName: "Kördiagram" },
        { value: "donut", displayName: "Fánkdiagram" },
    ];

    useEffect(() => {
        getModifiers();
    }, []);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                <Typography color="inherit" variant="body1">
                    Vezérlőpult
                </Typography>
            </Breadcrumbs>
            <div>
                <CssBaseline />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth={"xl"} className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Reports />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper className={classes.paper}>
                                    <ReportChart
                                        url={"/admin/orders/accounting"}
                                        siteType={"admin"}
                                        chartName={"accountingReport"}
                                        dataType={"unordered"}
                                        charts={accountingCharts}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper className={classes.paper}>
                                    {modifiers && (
                                        <ReportChart
                                            url={
                                                "/admin/orders/order-items/accounting"
                                            }
                                            siteType={"admin"}
                                            chartName={"orderItemReport"}
                                            dataType={"ordered"}
                                            modifiers={modifiers}
                                            charts={orderItemCharts}
                                        />
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </div>
        </div>
    );
};

export default Dashboard;
