/** @format */

import { useMediaQuery } from '@mui/material';
import { getApi } from 'api/axios';
import sizes from 'constants/sizes';
import { useState, useEffect } from 'react';
import ApexCharts from 'apexcharts';

export const useReportChartLogics = (url, chartType, chartName, dataType, modifiers) => {
  const [orderedData, setOrderedData] = useState();
  const [orderedDataLoading, setOrderedDataLoading] = useState(false);
  const desktop = useMediaQuery(`(min-width:${sizes.MD.toString()}px)`);

  const fetchOrderedItems = async () => {
    try {
      setOrderedDataLoading(true);
      const res = await (
        await getApi({
          baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL
        })
      ).get(
        `${url}${localStorage.getItem(chartName)}${
          !localStorage.getItem(chartName) ? `?size=10000&page=1` : '&size=10000&page=1'
        }`
      );
      if (res.status === 200) {
        //If gate for default 1 week
        if (!localStorage.getItem(chartName).includes('createdAt')) {
          let lastDataDate = res.data._embedded[Object.keys(res.data._embedded)[0]][0]?.createdAt;
          setOrderedData(
            res.data._embedded[Object.keys(res.data._embedded)[0]].filter(
              (data) =>
                new Date(data?.createdAt).getTime() <= new Date(lastDataDate).getTime() &&
                new Date(data?.createdAt).getTime() >= new Date(lastDataDate).getTime() - 7 * 24 * 60 * 60 * 1000
            )
          );
        } else {
          setOrderedData(res.data._embedded[Object.keys(res.data._embedded)[0]]);
        }
      }
      setOrderedDataLoading(false);
    } catch (e) {
      console.log(e);
      setOrderedDataLoading(false);
    }
  };

  const baseState = () => {
    const series = [];
    const options = {
      chart: {
        type: chartType,
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: 'Bevételi adatok',
        align: 'left'
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: 'Összeg'
        }
      },
      fill: {
        opacity: 1
      },
      noData: {
        text: 'Nincs elérhető adat',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          }
        }
      }
    };

    return { series, options };
  };

  const [newShopData, setNewShopData] = useState();
  const [stateB, setStateB] = useState(baseState);
  const [stateC, setStateC] = useState(baseState);

  function parseDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return { year, month, day };
  }

  useEffect(() => {
    if (orderedData) {
      setNewShopData(() => {
        const newData = {};
        Object.keys(orderedData).forEach((key) => {
          const entry = orderedData[key];
          const parsedDate = parseDate(entry.createdAt);
          newData[key] = {
            ...entry,
            createdAt: `${parsedDate.year}-${parsedDate.month}-${parsedDate.day}`
          };
        });

        const uniqueShopIds = [...new Set(Object.values(newData).map((entry) => entry.shopId))];
        const uniqueDates = [...new Set(Object.values(newData).map((entry) => entry.createdAt))];

        const combinations = [];
        uniqueShopIds.forEach((shopId) => {
          uniqueDates.forEach((date) => {
            combinations.push({ shopId, date });
          });
        });

        const existingCombinations = Object.values(newData).map((entry) => ({
          shopId: entry.shopId,
          date: entry.createdAt
        }));
        const missingCombinations = combinations.filter(
          (combination) =>
            !existingCombinations.find(
              (existingCombination) =>
                existingCombination.shopId === combination.shopId && existingCombination.date === combination.date
            )
        );

        const zeroData = missingCombinations.map((combination) => {
          const zeroEntry = {
            shopId: combination.shopId,
            createdAt: combination.date,
            grossAmount: 0,
            schoolName: '',
            className: ''
          };
          const existingEntry = Object.values(newData).find((entry) => entry.shopId === combination.shopId);
          if (existingEntry) {
            zeroEntry.schoolName = existingEntry.schoolName;
            zeroEntry.className = existingEntry.className;
          }
          return zeroEntry;
        });

        const finalData = Object.values(newData)
          .concat(zeroData)
          .sort(
            (a, b) => new Date(...a.createdAt.split('/').reverse()) - new Date(...b.createdAt.split('/').reverse())
          );

        return finalData;
      });
    }
  }, [orderedData]);

  useEffect(() => {
    if (newShopData) {
      setStateB(() => {
        const classDateToGrossAmountMapping = {};

        newShopData?.forEach((item) => {
          const key = `${item.schoolName} ${item.className} ${item.createdAt}`;
          classDateToGrossAmountMapping[key] = (classDateToGrossAmountMapping[key] || 0) + item.grossAmount;
        });

        const uniqueDates = [...new Set(newShopData?.map((item) => item.createdAt))];
        const uniqueClasses = [...new Set(newShopData?.map((item) => `${item.schoolName} ${item.className}`))];
        let relevantModifiers = modifiers
          ?.filter((m) => m?.priority !== 1)
          ?.map((m) => {
            return {
              name: m.name,
              modifierUri: m.modifierUriSegment,
              quantity: 0
            };
          });
        let totalValue = 0;
        relevantModifiers?.forEach((m) => (totalValue = totalValue + m.quantity));
        newShopData.forEach((el) => {
          relevantModifiers = relevantModifiers?.map((m) =>
            el.modifier?.includes(m.modifierUri)
              ? {
                  ...m,
                  quantity: m.quantity + el.grossAmount
                }
              : { ...m }
          );
        });

        const series = uniqueClasses?.map((classKey) => {
          const classSeriesData = uniqueDates?.map((date) => {
            const key = `${classKey} ${date}`;
            return classDateToGrossAmountMapping[key] || 0;
          });
          return {
            name: classKey,
            data: classSeriesData
          };
        });

        const options = {
          chart: {
            type: chartType,
            height: 350
          },
          colors: [
            '#e4b985',
            '#066342',
            '#d04119',
            '#c5db6c',
            '#033da0',
            '#df4dbb',
            '#6c7fab',
            '#fbb12c',
            '#184799',
            '#b3717b',
            '#a7d1d4',
            '#1c72e9',
            '#8a08b8',
            '#c00603',
            '#ff99b6',
            '#1df0a8',
            '#07d2e9',
            '#7c9992',
            '#828b83',
            '#61f3d6',
            '#e7a0fc',
            '#81d8d6',
            '#eb445e',
            '#e64332',
            '#3421f1',
            '#47522d',
            '#9b57d0',
            '#7e260b',
            '#eca591',
            '#d1eaca'
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          title: {
            text: 'Bevétel (Ft)',
            align: 'left'
          },
          stroke: {
            show: true,
            width: 4,
            colors: undefined
          },
          xaxis: {
            categories: uniqueDates?.map((date) => new Date(date).toLocaleDateString())
          },
          yaxis: {
            title: {
              text: 'Összeg'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              }
            }
          },
          noData: {
            text: 'Nincs elérhető adat',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        };

        return { series, options };
      });
    }
  }, [newShopData, modifiers]);

  useEffect(() => {
    if (newShopData && dataType === 'ordered') {
      let relevantModifiers = modifiers
        ?.filter((m) => m?.priority !== 1)
        ?.map((m) => {
          return {
            name: m.name,
            modifierUri: m.modifierUriSegment,
            quantity: 0
          };
        });
      let totalValue = 0;
      relevantModifiers?.forEach((m) => (totalValue = totalValue + m.quantity));
      newShopData.forEach((el) => {
        relevantModifiers = relevantModifiers?.map((m) =>
          el.modifier?.includes(m.modifierUri)
            ? {
                ...m,
                quantity: m.quantity + el.grossAmount
              }
            : { ...m }
        );
      });
      if (chartType !== 'bar') {
        setStateB(() => {
          const series = relevantModifiers?.map((m) => {
            return m.quantity;
          });
          ApexCharts.exec(
            chartType,
            'updateSeries',
            relevantModifiers?.map((m) => {
              return m.quantity;
            })
          );

          const options = {
            chart: {
              type: chartType,
              height: 355
            },
            title: {
              text: 'Bevétel terméktípusonként (Ft)',
              align: 'left'
            },
            labels: relevantModifiers?.map((m) => m.name),
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    height: 355
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }
            ],
            tooltip: {
              enabled: true,
              custom: function ({ series, seriesIndex, w }) {
                return (
                  '<div style="margin: 0.5rem">' +
                  '<span>' +
                  w.config.labels[seriesIndex] +
                  ': ' +
                  series[seriesIndex] +
                  '</span>' +
                  '</div>'
                );
              }
            },
            fill: {
              type: 'gradient'
            },
            noData: {
              text: 'Nincs elérhető adat',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: chartType === 'pie' ? false : true,
                    name: {
                      show: true
                    },
                    value: {
                      offsetY: -1,
                      show: true
                    },
                    total: {
                      show: true,
                      showAlways: true,
                      fontSize: desktop ? '14' : '10',
                      label: 'Teljes bevétel'
                    }
                  }
                }
              }
            },
            colors: [
              '#e4b985',
              '#066342',
              '#d04119',
              '#c5db6c',
              '#033da0',
              '#df4dbb',
              '#6c7fab',
              '#fbb12c',
              '#184799',
              '#b3717b',
              '#a7d1d4',
              '#1c72e9',
              '#8a08b8',
              '#c00603',
              '#ff99b6',
              '#1df0a8',
              '#07d2e9',
              '#7c9992',
              '#828b83',
              '#61f3d6',
              '#e7a0fc',
              '#81d8d6',
              '#eb445e',
              '#e64332',
              '#3421f1',
              '#47522d',
              '#9b57d0',
              '#7e260b',
              '#eca591',
              '#d1eaca'
            ]
          };
          return { series, options };
        });
      } else {
        setStateC(() => {
          const series = [
            {
              name: 'Bevétel',
              data: relevantModifiers?.map((m) => {
                return m.quantity;
              })
            }
          ];

          const options = {
            chart: {
              type: chartType,
              height: 350
            },
            colors: [
              '#e4b985',
              '#066342',
              '#d04119',
              '#c5db6c',
              '#033da0',
              '#df4dbb',
              '#6c7fab',
              '#fbb12c',
              '#184799',
              '#b3717b',
              '#a7d1d4',
              '#1c72e9',
              '#8a08b8',
              '#c00603',
              '#ff99b6',
              '#1df0a8',
              '#07d2e9',
              '#7c9992',
              '#828b83',
              '#61f3d6',
              '#e7a0fc',
              '#81d8d6',
              '#eb445e',
              '#e64332',
              '#3421f1',
              '#47522d',
              '#9b57d0',
              '#7e260b',
              '#eca591',
              '#d1eaca'
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                distributed: true
              }
            },
            dataLabels: {
              enabled: false
            },
            title: {
              text: 'Bevétel terméktípusonként (Ft)',
              align: 'left'
            },
            stroke: {
              show: true,
              width: 4,
              colors: undefined
            },
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: false
              }
            },
            xaxis: {
              categories: relevantModifiers?.map((m) => m.name),
              labels: {
                show: false
              }
            },
            yaxis: {
              title: {
                text: 'Összeg'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val;
                }
              }
            },
            noData: {
              text: 'Nincs elérhető adat',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
              }
            }
          };

          return { series, options };
        });
      }
    }
  }, [newShopData, chartType, modifiers]);

  return { orderedData, orderedDataLoading, fetchOrderedItems, stateB, stateC, baseState };
};
