import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useUploaderBl } from './hooks/useUploaderBl';
import * as Styles from './styles';
import { useDispatch } from 'react-redux';
import { resetErrorCount } from 'redux/slices/UploadSlice';

const Uploader = ({ group, fetchProducts, products, disabled }) => {
  const { fileInputRef, handleInputClick, handleFileUpload } = useUploaderBl();

  const [isHovering, setIsHovering] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetErrorCount());
  }, [group, dispatch]);

  return (
    <Styles.Wrapper>
      {disabled ? (
        <Tooltip
          title={
            'Elérted demo galériád képfeltöltési limitjét! Ha több képet szeretnél feltölteni, élesítsd boltodat!'
          }>
          <Styles.UploadButton
            onClick={() => {}}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}>
            <Styles.UploadIcon hover={isHovering} />
            <Styles.UploadText hover={isHovering}>Feltöltés letiltva</Styles.UploadText>
          </Styles.UploadButton>
        </Tooltip>
      ) : (
        <Styles.UploadButton
          onClick={handleInputClick}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}>
          <Styles.UploadIcon hover={isHovering} />
          <Styles.UploadText hover={isHovering}>Feltöltés</Styles.UploadText>
        </Styles.UploadButton>
      )}
      <input
        ref={fileInputRef}
        hidden
        type='file'
        accept='.jpg, .jpeg'
        onChange={(e) => {
          handleFileUpload(e, group?.id, group?.modifiers, fetchProducts, products);
        }}
        multiple
      />
    </Styles.Wrapper>
  );
};

export default Uploader;
