/** @format */

import { useGetQueryString } from "hooks/useGetQueryString";
import React from "react";
import { useState } from "react";
import QueryOptions from "./config/QueryOptions";
import PrinterQueryOptions from "./config/PrinterQueryOptions";
import GroupQueryOptions from "./config/GroupQueryOptions";
import ReportingQueryOptions from "./config/ReportingQueryOptions";
import AdminQueryOptions from "./config/AdminQueryOptions";
import PaymentQueryOptions from "./config/PaymentQueryOptions";
import QueryParam from "./QueryParam/QueryParam";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
    Wrapper,
    OptionsWrapper,
    ActionsWrapper,
    AddWrapper,
    TitleWrapper,
    SearchWrapper,
    Option,
    useStyles,
} from "./styles";
import {
    Button,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useEffect } from "react";
import PaginationEditor from "./QueryParamEditor/PaginationEditor/PaginationEditor";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import RefreshIcon from "@mui/icons-material/Refresh";
const QueryBuilder = ({
    itemsLength,
    showAll,
    children,
    siteType,
    OriqueryParams,
    reset,
    refresh,
}) => {
    const theme = useTheme();
    const { search } = useLocation();
    const navigate = useNavigate();

    const classes = useStyles(theme);

    //TODO check value validity
    const getInitialOptions = ({ options }) => {
        const searchParams = new URLSearchParams(search);

        return options?.map((o) => {
            if (search.includes(o.name)) {
                let value = searchParams.get(o.name);
                if (value === null) {
                    // it might be a date
                    let fromValue = searchParams.get(`${o.name}FromDateTime`);
                    let toValue = searchParams.get(`${o.name}ToDateTime`);
                    if (fromValue && toValue) {
                        return {
                            ...o,
                            used: true,
                            value: `${o.name}FromDateTime=${fromValue}&${o.name}ToDateTime=${toValue}`,
                        };
                    }

                    return { ...o, used: false, value: "" };
                }
                return { ...o, used: true, value: `${o.name}=${value}` };
            } else if (search.includes("fromDateTime") && o.name !== "pagination") {
                let value = searchParams.get("fromDateTime");
                let value2 = searchParams.get("toDateTime");
                if (value === null) {
                    // it might be a date
                    let fromValue = searchParams.get(`fromDateTime`);
                    let toValue = searchParams.get(`toDateTime`);
                    if (fromValue && toValue) {
                        return {
                            ...o,
                            used: true,
                            value: `fromDateTime=${fromValue}&toDateTime=${toValue}`,
                        };
                    }

                    return { ...o, used: false, value: "" };
                }
                return {
                    ...o,
                    used: true,
                    value: `fromDateTime=${value}&toDateTime=${value2}`,
                };
            } else {
                if (o.name === "pagination") {
                    if (siteType === "reporting") {
                        let size = searchParams.get(`pageSize`);
                        let page = searchParams.get(`pageNumber`);
                        if (showAll) {
                            return {
                                ...o,
                                used: true,
                                value: `pageSize=${itemsLength}&pageNumber=${1}`,
                            };
                        }
                        if (size && page) {
                            // it is a pagination data
                            return {
                                ...o,
                                used: true,
                                value: `pageSize=${size}&pageNumber=${page}`,
                            };
                        }
                    } else {
                        let size = searchParams.get(`size`);
                        let page = searchParams.get(`page`);
                        if (showAll) {
                            return {
                                ...o,
                                used: true,
                                value: `size=${itemsLength}&page=${1}`,
                            };
                        }
                        if (size && page) {
                            // it is a pagination data
                            return {
                                ...o,
                                used: true,
                                value: `size=${size}&page=${page}`,
                            };
                        }
                    }
                }
                return { ...o, used: false, value: "" };
            }
        });
    };

    const { getQueryString } = useGetQueryString();
    const [options, setOptions] = useState(
        getInitialOptions({
            options:
                siteType === "printer"
                    ? PrinterQueryOptions
                    : siteType === "group"
                    ? GroupQueryOptions
                    : siteType === "reporting"
                    ? ReportingQueryOptions
                    : siteType === "admin"
                    ? AdminQueryOptions
                    : siteType === "payment" 
                    ? PaymentQueryOptions
                    : QueryOptions,
        })
    );
    const [currentQueryString, setCurrentQueryString] = useState(search);
    const [isAddDisabled, setIsAddDisabled] = useState(false);
    const [isAddOptionOpen, setIsAddOptionOpen] = useState(false);

    const toggleIsAddOptionOpen = () => setIsAddOptionOpen(!isAddOptionOpen);

    useEffect(() => {
        setCurrentQueryString(getQueryString(options));
    }, [options]);

    useEffect(() => {
        if (search !== currentQueryString) {
            if (siteType === "group" || siteType === "admin") {
                navigate({
                    pathName: "/printing",
                    search: currentQueryString,
                });
            } else if (siteType === "printer") {
                navigate({
                    pathName: "/printer",
                    search: currentQueryString,
                });
            } else if (siteType === "reporting") {
                navigate({
                    pathName: "/superadmin/dashboard",
                    search: currentQueryString,
                });
            } else if (siteType === "payment") {
                navigate({
                    pathName: "/superadmin/payments",
                    search: currentQueryString,
                });
            } else {
                navigate({
                    pathName: "/orders",
                    search: currentQueryString,
                });
            }
        }
    }, [currentQueryString]);
    useEffect(() => {
        setOptions(
            getInitialOptions({
                options:
                    siteType === "printer"
                        ? PrinterQueryOptions
                        : siteType === "group"
                        ? GroupQueryOptions
                        : siteType === "reporting"
                        ? ReportingQueryOptions
                        : siteType === "admin"
                        ? AdminQueryOptions
                        : siteType === "payment" 
                        ? PaymentQueryOptions
                        : QueryOptions,
            })
        );
        if (search !== currentQueryString) {
            setCurrentQueryString(search);
        }
    }, [search, showAll]);

    const setOption = (option) => {
        setOptions(
            options.map((currentOption) => {
                if (currentOption.name === option.name) {
                    return option;
                } else {
                    return currentOption;
                }
            })
        );
    };

    const disableOption = (option) => {
        setOptions(
            options.map((currentOption) => {
                if (currentOption.name === option.name) {
                    currentOption.used = false;
                    currentOption.value = "";
                }
                return currentOption;
            })
        );
    };
    const enableOption = (name) => {
        setOptions(
            options.map((currentOption) => {
                if (currentOption.name === name) {
                    currentOption.used = true;
                }
                return currentOption;
            })
        );

        setIsAddOptionOpen(false);
    };

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Wrapper
                className={
                    siteType === "printer"
                        ? classes.printerWrapper
                        : siteType === "group" || siteType === "admin"
                        ? classes.groupWrapper
                        : classes.normalWrapper
                }
            >
                <SearchWrapper
                    backgroundColor={
                        siteType === "group"
                            ? "transparent"
                            : theme.palette.primary.backgroundSecondary
                    }
                >
                    <TitleWrapper>
                        <Typography
                            component="div"
                            variant="h2"
                            style={{
                                color:
                                    siteType === "group"
                                        ? theme.palette.primary
                                              .backgroundSecondary
                                        : theme.palette.primary.contrastText,
                                fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
                                fontSize: "2.5rem",
                            }}
                        >
                            Keresés
                        </Typography>
                        <PaginationEditor
                            options={options}
                            setOptions={setOptions}
                            ordersLength={itemsLength}
                            showAll={showAll}
                            siteType={siteType}
                        />
                    </TitleWrapper>
                    {options.map(
                        (option) =>
                            option.used &&
                            option.type !== "pagination" && (
                                <QueryParam
                                    key={`${option.name}_queryparam`}
                                    option={{ ...option }}
                                    setOption={setOption}
                                    disableOption={() => disableOption(option)}
                                    setIsAddDisabled={setIsAddDisabled}
                                />
                            )
                    )}
                    <AddWrapper>
                        <Tooltip title={"Keresési szűrő hozzáadása"}>
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor: isAddOptionOpen
                                        ? theme.palette.primary.main
                                        : theme.palette.primary
                                              .backgroundContent,
                                    color: isAddOptionOpen
                                        ? theme.palette.primary
                                              .backgroundContent
                                        : theme.palette.primary.main,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                }}
                                onClick={toggleIsAddOptionOpen}
                                disabled={isAddDisabled}
                            >
                                {isAddOptionOpen ? <RemoveIcon /> : <AddIcon />}
                                Szűrő hozzáadása
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Keresés alaphelyzetbe állítása"}>
                            <Button
                                style={{
                                    backgroundColor:
                                        theme.palette.primary.backgroundContent,
                                    color: theme.palette.primary.main,
                                    margin: 0,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    reset();
                                }}
                            >
                                <RestartAltIcon
                                    style={{ fontSize: "1.5rem" }}
                                />
                                Szűrők törlése
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Tételek frissítése"}>
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor:
                                        theme.palette.primary.backgroundContent,
                                    color: theme.palette.primary.main,
                                    marginTop: 0,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                    transition: "background 300ms",
                                    // opacity: !deliveries?.find(
                                    //   (delivery) => delivery.selected
                                    // )
                                    //   ? 0.3
                                    //   : 1,
                                }}
                                onClick={() => refresh()}
                            >
                                <RefreshIcon style={{ fontSize: "1.5rem" }} />
                                {siteType === "printer" ||
                                siteType === "group" ||
                                siteType === "admin"
                                    ? "Iskolák frissítése"
                                    : siteType === "reporting"
                                    ? "Jelentések frissítése"
                                    : siteType === 'payment'
                                    ? "Fizetések frissítése"
                                    : "Rendelések frissítése"}
                            </Button>
                        </Tooltip>
                        {isAddOptionOpen && (
                            <OptionsWrapper
                                backgroundColor={
                                    theme.palette.primary.backgroundContent
                                }
                                borderColor={theme.palette.primary.main}
                            >
                                {options.map(
                                    (option) =>
                                        !option.used &&
                                        option.type !== "pagination" && (
                                            <Option
                                                backgroundColor={
                                                    theme.palette.primary
                                                        .backgroundContent
                                                }
                                                color={
                                                    theme.palette.primary.main
                                                }
                                                fontFamily={
                                                    theme.typography.main
                                                }
                                                key={`${option.name}_option`}
                                                onClick={(e) =>
                                                    enableOption(option.name)
                                                }
                                            >
                                                {option.displayName}
                                            </Option>
                                        )
                                )}
                            </OptionsWrapper>
                        )}
                    </AddWrapper>
                </SearchWrapper>
                <ActionsWrapper
                    backgroundColor={theme.palette.primary.backgroundSecondary}
                >
                    <div>
                        {
                            // Placeholder.
                        }
                    </div>
                </ActionsWrapper>
                {children}
                <PaginationEditor
                    options={options}
                    setOptions={setOptions}
                    ordersLength={itemsLength}
                    showAll={showAll}
                    siteType={siteType}
                />
            </Wrapper>
        </div>
    );
};

export default QueryBuilder;
