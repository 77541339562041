import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import headercss from "./Header.css";

const useStyles = makeStyles((theme) => ({
  listItem: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial !important`,
  },
}));

const HeaderOption = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ListItem
      className={classes.listItem}
      disabled={props.disabled}
      onClick={props.click}
      button
      key={props.name}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.name} />
    </ListItem>
  );
};

export default HeaderOption;
