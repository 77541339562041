import { useEffect } from 'react';

export const useBatchLogic = (
  fetchInnerGroups,
  innerGroups,
  setInnerGroups,
  deleteGroup,
  fetchProducts,
  products,
  setProducts,
  putProduct,
  deleteProduct,
  setConfirmProdOpen,
  id,
  getQueryString,
  setSelected,
  fetchAllProducts
) => {
  useEffect(() => {
    if (innerGroups?.find((prod) => prod.selected === true) !== undefined) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [innerGroups]);

  useEffect(() => {
    if (products?.find((prod) => prod.selected === true) !== undefined) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [products]);

  const selectItems = (items, id, isGallery) => {
    const newItems = items.map((product) => {
      if (product.id === id) {
        return { ...product, selected: !product.selected };
      }
      return product;
    });
    if (isGallery) {
      setInnerGroups(newItems);
    } else {
      setProducts(newItems);
    }
  };

  const handleSelectAll = (items, e, isGallery) => {
    const newItems = items.map((item) => ({
      ...item,
      selected: e.target.checked
    }));
    if (isGallery) {
      setInnerGroups(newItems);
    } else {
      setProducts(newItems);
    }
  };
  const queryParams = [
    {
      name: 'sort',
      value: `sort=name,desc`
    },

    {
      name: 'size',
      value: `size=${products?.length}`
    },
    {
      name: 'page',
      value: `page=1`
    }
  ];
  const deleteSelectedProducts = async () => {
    setConfirmProdOpen(false);
    let completed = [];
    products.forEach((product) => {
      if (product.selected) {
        let productDelete = deleteProduct({ id: product.id });
        completed.push(productDelete);
      }
    });
    await Promise.all(completed);
    await fetchProducts({
      id: id,
      queryString: getQueryString(queryParams)
    });
    await fetchAllProducts({
      id: id
    });
  };

  const enableSelectedProducts = async () => {
    let completed = [];
    products.forEach((product) => {
      if (product.selected) {
        if (product.isActive) {
          product.isActive = false;
          product.modifiers = null;
          delete product.selected;
          let productDelete = putProduct({ body: product });
          completed.push(productDelete);
        } else {
          product.isActive = true;
          product.modifiers = null;
          delete product.selected;
          let productDelete = putProduct({ body: product });
          completed.push(productDelete);
        }
      }
    });
  };

  const deleteSelectedGroups = async () => {
    setConfirmProdOpen(false);
    let completed = [];
    innerGroups.forEach((group) => {
      if (group.selected) {
        let groupDelete = deleteGroup({ id: group.id });
        completed.push(groupDelete);
      }
    });
    await Promise.all(completed);
    await fetchInnerGroups({ id: id });
  };
  /*const enableAllGroups = async () => {
    setConfirmProdOpen(false);
    let completed = [];
    if (innerGroups?.find((group) => group.isActive === false) === undefined) {
      innerGroups.forEach((group) => {
        group.isActive = false;
        delete group.selected;
        let groupUpdate = putGroup({ body: group,  });
        completed.push(groupUpdate);
      });
    } else {
      innerGroups.forEach((group) => {
        if (!group.isActive) {
          group.isActive = true;
          delete group.selected;
          let groupUpdate = putGroup({ body: group,  });
          completed.push(groupUpdate);
        }
      });
    }

    await Promise.all(completed);
    await fetchInnerGroups({ id: id,  });
  };*/
  return {
    deleteSelectedGroups,
    deleteSelectedProducts,
    handleSelectAll,
    selectItems,
    enableSelectedProducts
  };
};
