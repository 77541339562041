import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import PersonIcon from "@material-ui/icons/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import NotificationsIcon from "@mui/icons-material/Notifications";
import iconPng from "assets/icons/logo.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import { Print } from "@material-ui/icons";
import { BarChart, PaymentOutlined } from "@mui/icons-material";

/*
 {
        name: 'Vezérlőpult',
        icon: <DashboardIcon style={{ color: 'whitesmoke' }} />,
        to: '/administration/dashboard',
        divider: false,
        disabled: false
      },
*/

/*
{
        name: 'Fizetés',
        icon: <CreditCardIcon style={{ color: 'whitesmoke' }} />,
        to: 'payments',
        divider: false,
        disabled: false,
      },

      
      {
        name: 'Általános',
        icon: <BuildIcon style={{ color: 'whitesmoke' }} />,
        to: 'general',
        divider: false,
        disabled: true,
      },
      {
        name: 'Termékek',
        icon: <CategoryIcon style={{ color: 'whitesmoke' }} />,
        to: 'products',
        divider: false,
        disabled: true
      },

            {
        name: 'Profil',
        icon: <PersonIcon style={{ color: 'whitesmoke' }} />,
        to: 'profile',
        divider: false,
        disabled: false
      },

            {
        name: 'Megjelenés',
        icon: <BrushIcon style={{ color: 'whitesmoke' }} />,
        to: 'appearance',
        divider: false,
        disabled: false
      },
*/

const HeaderData = {
  icon: iconPng,
  siteName: "Qdak photo portal",
  options: {
    admin: [
      {
        name: "Rendelések",
        icon: <ShoppingCartIcon style={{ color: "whitesmoke" }} />,
        to: "orders",
        divider: false,
        disabled: false,
      },
      {
        name: "Kimutatások",
        icon: <BarChart style={{ color: "whitesmoke" }} />,
        to: "reports",
        divider: false,
        disabled: false,
      },
      {
        name: "Nyomtatás",
        icon: <Print style={{ color: "whitesmoke" }} />,
        to: "printing",
        divider: false,
        disabled: false,
      },
      {
        name: "Árazás",
        icon: <PriceChangeIcon style={{ color: "whitesmoke" }} />,
        to: "templates",
        divider: false,
        disabled: false,
      },
      {
        name: "Iskolák",
        icon: <PhotoLibraryIcon style={{ color: "whitesmoke" }} />,
        to: "galleries",
        divider: false,
        disabled: false,
      },
    ],
    superAdmin: [
      {
        name: "Vezérlőpult",
        icon: <DashboardIcon style={{ color: "whitesmoke" }} />,
        to: "dashboard",
        divider: false,
        disabled: false,
      },
      {
        name: "Fizetések",
        icon: <PaymentOutlined style={{ color: "whitesmoke" }} />,
        to: "payments",
        divider: false,
        disabled: false,
      },
      {
        name: "Árazás",
        icon: <PriceChangeIcon style={{ color: "whitesmoke" }} />,
        to: "pricing",
        divider: false,
        disabled: false,
      },
      {
        name: "Felhasználók",
        icon: <PeopleAltIcon style={{ color: "whitesmoke" }} />,
        to: "users",
        divider: false,
        disabled: false,
      },
      {
        name: "Tájékoztatók",
        icon: <NotificationsIcon style={{ color: "whitesmoke" }} />,
        to: "notifications",
        divider: false,
        disabled: false,
      },
      {
        name: "Blogok",
        icon: <MenuBookIcon style={{ color: "whitesmoke" }} />,
        to: "blogs",
        divider: false,
        disabled: false,
      },
    ],
    printerAdmin: {
      name: "Nyomtatás",
      icon: <Print style={{ color: "whitesmoke" }} />,
      to: "printer",
      divider: false,
      disabled: false,
    },
    site: [
      {
        name: "Jogosultságok",
        icon: <PeopleIcon style={{ color: "whitesmoke" }} />,
        to: "roles",
        divider: false,
        disabled: false,
      },
      {
        name: "Számlázás",
        icon: <ReceiptLongIcon style={{ color: "whitesmoke" }} />,
        to: "invoicing",
        divider: false,
        disabled: false,
      },
      {
        name: "Dokumentumok",
        icon: <DescriptionIcon style={{ color: "whitesmoke" }} />,
        to: "documents",
        divider: false,
        disabled: false,
      },
    ],
    profile: [
      {
        name: "Profil beállítások",
        icon: <PersonIcon style={{ color: "whitesmoke" }} />,
        to: "profile",
        divider: false,
        disabled: false,
      },
    ],
  },
  logoutOption: {
    name: "Kijelentkezés",
    to: "/",
    icon: <ExitToAppIcon style={{ color: "whitesmoke" }} />,
    onClick: (logoutFn) => {
      logoutFn();
      return false;
    },
    divider: false,
    disabled: false,
  },
};

export default HeaderData;
