/** @format */

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import ReportChart from "components/SuperAdminComponents/Dashboard/ReportChart/ReportChart";
import { useSelector } from "react-redux";
import { useModifierApi } from "../Templates/hooks/useModifierApi";
import { useEffect } from "react";

const PhotoReports = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const shopId = useSelector(
        (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
    );
    const accountingCharts = [
        { value: "bar", displayName: "Oszlopdiagram" },
        { value: "line", displayName: "Vonaldiagram" },
    ];
    const orderItemCharts = [
        { value: "bar", displayName: "Oszlopdiagram" },
        { value: "pie", displayName: "Kördiagram" },
        { value: "donut", displayName: "Fánkdiagram" },        
    ];

   

    const { modifiers, getModifiers } = useModifierApi();

    useEffect(() => {
        getModifiers();
    }, []);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                <Typography color="inherit" variant="body1">
                    Kimutatások
                </Typography>
            </Breadcrumbs>
            <div>
                <CssBaseline />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth={"xl"} className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {/* <Paper className={classes.paper}>
                  <Reports />
                </Paper> */}
                            </Grid>
                            <Grid item xs={12} lg={6}>                               
                                    <ReportChart
                                        url={`/admin/shops/${shopId}/orders/accounting`}
                                        siteType={"user"}
                                        chartName={"accountingPhotoReport"}
                                        dataType={"unordered"}
                                        charts={accountingCharts}
                                    />                                
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                    <ReportChart
                                        url={`/admin/shops/${shopId}/orders/order-items/accounting`}
                                        siteType={"user"}
                                        chartName={"orderItemPhotoReport"}
                                        dataType={"ordered"}
                                        modifiers={modifiers}
                                        charts={orderItemCharts}
                                    />                               
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </div>
        </div>
    );
};

export default PhotoReports;
