import { Grid, TextField, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React from 'react';
import { useEffect } from 'react';

import { usePostalCodeFields } from './hooks/usePostalCodeFields';
import { useStyles } from './styles';
import { Autocomplete } from '@mui/material';

const PostalCodeFields = ({ data, setData, billing, handleChange, zipHelper, zipError, cityHelper, cityError }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { calcAddress, calcPostalCode, calcAddressSuggestions, calcZipSuggestions, zips, settlements } =
    usePostalCodeFields();

  useEffect(() => {
    if (billing) {
      calcZipSuggestions(data?.billingCity, data?.billingZipCode);
      if (data?.billingZipCode?.length > 0) {
        const suggestion = calcAddress(data.billingZipCode);
        if (
          data?.billingCity &&
          settlements[Object.keys(settlements)?.find((key) => key === data?.billingCity)] == data?.billingZipCode
        ) {
          return;
        } else {
          setData({ ...data, billingCity: suggestion || '' });
        }
      } else if (data?.billingCity.length > 1 && Object.keys(settlements)?.find((key) => key === data?.billingCity)) {
        setData({ ...data, billingCity: '' });
      }
    } else {
      calcZipSuggestions(data?.contactCity, data?.contactZipCode);
      if (data?.contactZipCode?.length > 0) {
        const suggestion = calcAddress(data.contactZipCode);
        if (
          data?.contactCity &&
          settlements[Object.keys(settlements)?.find((key) => key === data?.contactCity)] == data?.contactZipCode
        ) {
          return;
        } else {
          setData({ ...data, contactCity: suggestion || '' });
        }
      } else if (data?.contactCity.length > 1 && Object.keys(settlements)?.find((key) => key === data?.contactCity)) {
        setData({ ...data, contactCity: '' });
      }
    }
  }, [data?.contactZipCode, data?.billingZipCode]);

  useEffect(() => {
    if (billing) {
      calcZipSuggestions(data?.billingCity, data?.billingZipCode);
      if (data?.billingCity?.length > 0) {
        const suggestion = calcPostalCode(data.billingCity, data.billingZipCode);
        setData({ ...data, billingZipCode: suggestion || '' });
      } else if (data?.billingZipCode.length > 1) {
        setData({ ...data, billingZipCode: '' });
      }
    } else {
      calcZipSuggestions(data?.contactCity, data?.contactZipCode);
      if (data?.contactCity?.length > 0) {
        const suggestion = calcPostalCode(data.contactCity, data.contactZipCode);
        setData({ ...data, contactZipCode: suggestion || '' });
      } else if (data?.contactZipCode?.length > 1) {
        setData({ ...data, contactZipCode: '' });
      }
    }
  }, [data?.contactCity, data?.billingCity]);

  return (
    <>
      <Grid item xs={12} md={6} className={classes.container}>
        <Typography variant='h4' className={classes.label}>
          Irányítószám
        </Typography>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disablePortal
          options={zips}
          filterOptions={(options) => options}
          inputValue={billing ? data?.billingZipCode : data?.contactZipCode}
          className={classes.autoComplete}
          sx={{ fontFamily: `${theme.typography.main}, Sans-serif, Arial` }}
          onChange={(e) => {
            if (billing) {
              setData({ ...data, billingZipCode: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML });
            } else {
              setData({ ...data, contactZipCode: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML });
            }
          }}
          renderInput={(params) => (
            <TextField
              id='zip'
              required
              name='zip'
              value={billing ? data?.billingZipCode : data?.contactZipCode}
              className={classes.input}
              placeholder='0000'
              {...params}
              key='postal_code_field'
              onChange={(e) => {
                //handleChange(e);
                if (billing) {
                  setData({ ...data, billingZipCode: e.target.value });
                } else {
                  setData({ ...data, contactZipCode: e.target.value });
                }
              }}
              error={zipHelper && Boolean(zipError)}
              helperText={zipHelper && zipError}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.container}>
        <Typography variant='h4' className={classes.label}>
          Település
        </Typography>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disablePortal
          options={calcAddressSuggestions(billing ? data?.billingCity : data?.contactCity)}
          inputValue={billing ? data?.billingCity : data?.contactCity}
          className={classes.autoComplete}
          sx={{ fontFamily: `${theme.typography.main}, Sans-serif, Arial` }}
          onChange={(e) => {
            if (billing) {
              setData({ ...data, billingCity: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML });
            } else {
              setData({ ...data, contactCity: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML });
            }
          }}
          renderInput={(params) => (
            <TextField
              id='city'
              required
              name='city'
              value={billing ? data?.billingCity : data?.contactCity}
              className={classes.input}
              {...params}
              key='address_field'
              onChange={(e) => {
                //handleChange(e);
                if (billing) {
                  setData({ ...data, billingCity: e.target.value });
                } else {
                  setData({ ...data, contactCity: e.target.value });
                }
              }}
              error={cityHelper && Boolean(cityError)}
              helperText={cityHelper && cityError}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default PostalCodeFields;
